import React from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';

import { Grid,
	GridItem,
	Heading,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	Text,
	Divider,
	Container,
	Box
} from '@chakra-ui/react'

const Scorer = (props) => {
	
	const [data, setData] = React.useState({ 
		seasonId: '',
		players: [],
		stats: [
			{
				type: 'goals',
				title: 'Top Goalscorer'
			},
			{
				type: 'assists',
				title: 'Most Assists'
			},
			{
				type: 'yc',
				title: 'Yellow Cards'
			},
			{
				type: 'rc',
				title: 'Red Cards'
			}
		]
	});

	

	const initialCall = () => {
		Promise.all([
			Axios.get('season/?id=' + props.Id),
		])
		.then((seasonResponse) => {
			Axios.get('league/scorers/?id=' + seasonResponse[0].data.season[0].id)
			.then((playersResponse) => {
				setData({ 
					seasonId: {seasonId: seasonResponse[0].data.season[0].id},
					players: playersResponse.data.player,
					stats: [
						{
							type: 'goals',
							title: 'Top Goalscorer'
						},
						{
							type: 'assists',
							title: 'Most Assists'
						},
						{
							type: 'yc',
							title: 'Yellow Cards'
						},
						{
							type: 'rc',
							title: 'Red Cards'
						}
					]
				});
			})
		}, (error) => {
		})
	}

	React.useEffect(() => {
		if (!props.Id) {
			return 
		}
		initialCall();

		// eslint-disable-next-line react-hooks/exhaustive-deps
  	}, [props.Id]);

	const displayData = () => {
	    return data.seasonId ? (
	      	<>
	      		<Grid
					templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', '2xl':'repeat(6, 1fr)' }}
					gap={6}
					bg='blackAlpha.900'
					padding={10}
				>
					{data.stats
						.map((dataKey, i) =>{

							let playerFilter = null


							if (props.TeamId != null){
								playerFilter = data.players.filter(playerKey => playerKey.teamid === props.TeamId)
							} else {
								playerFilter = data.players
							}
						return (
							<>
								<GridItem
									colSpan={{base: 1, md: 2, '2xl':6 }}
									w='100%'
									textAlign='center'
									color='white'
									textTransform='uppercase'
									key={dataKey.title}
								>
									<Heading>{ dataKey.title }</Heading>
								</GridItem>
								{playerFilter
                                    .sort((player1, player2) => (parseInt(player1[dataKey.type]) > parseInt(player2[dataKey.type])) ? -1 : 1)
									.slice(0, 6)
									.map((playerKey, i) =>{
										let stats = dataKey.type
										let img = ''
										let img2 = ''
										let imgHover = ''
										if (props.safeMode === 1){
											img = 'https://ws.blue-phoenix.co.uk/img/No-Image.jpg'
											img2 = 'https://ws.blue-phoenix.co.uk/img/No-Image.jpg'
											imgHover = 'https://ws.blue-phoenix.co.uk/img/No-Image.jpg'
										} else {
											img = playerKey.image ? playerKey.image : 'https://ws.blue-phoenix.co.uk/img/No-Image.jpg'
											img2 = playerKey.image2 ? playerKey.image2 : playerKey.image
											imgHover = playerKey.image ? img2 : 'https://ws.blue-phoenix.co.uk/img/No-Image.jpg'
										}
										return(
											<GridItem key={i}
												w='100%'>
												<Link to={'/player/' + playerKey.id}>
													<Card 
														bgImage={img}
														bgSize="cover"
														bgPos='center'
														minH="550px"
														_hover={{bgImage:imgHover }}
														>
														<CardHeader
															bg='transparent'
														>
															<Text
																fontSize="7xl"
																bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
																bgClip='text'
																lineHeight={1}
															>
																{playerKey[stats]}
															</Text>
															<Text
																lineHeight={1}
																bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
																bgClip='text'
																textTransform="uppercase"
															>
																Goals
															</Text>
														</CardHeader>
														<CardBody
															display="flex"
															alignItems="flex-end"
														>
														<Text
																fontSize="3xl"
																bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
																bgClip='text'
																lineHeight={1}
																textTransform="uppercase"
															>
																{playerKey.firstname} {playerKey.surname}
															</Text>
														</CardBody>
														<Divider />
														<CardFooter>
															<Text
																lineHeight={1}
																bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
																bgClip='text'
																textTransform="uppercase"
															>
																Player Profile
															</Text>
														</CardFooter>
													</Card>
												</Link>
											</GridItem>
										)
									})
								}
							</>
						)
					})}
				</Grid>
	        </>
	    ) : (
	    	<Container
					maxW='container.3xl'
					pt='75px;'
					pb='75px;'
				>
				<Box boxShadow='md' bg='white' w='100%' p={20}>
					<Heading size="2xl" textTransform="uppercase">No Stats Found</Heading>
				</Box>
			</Container>
	    );
	 }

	return(
		<>
			{displayData()}
        </>
	)
}

export default Scorer;