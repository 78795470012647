import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import {
	Heading,
	Text,
	Flex,
} from '@chakra-ui/react'

const fixture = (props) => {
	return(
		<>
			<Link key={props.key} to={'/fixture/' + props.id}>
				<Text
					textAlign="center"
					color="white"
				>
					{moment.unix(props.date).format("dddd D MMMM YYYY")}
				</Text>
				<Heading
					textAlign="center"
					color="white"
					fontSize="3xl"
					textTransform="uppercase"
				>
					{props.hometeam}
				</Heading>
				<Flex
					justifyContent="center"
					alignItems="center"
					gap={5}
				>
					<Heading
						textAlign="center"
						color="white"
						fontSize="6xl"
						textTransform="uppercase"
					>
						{props.homegoals}
					</Heading>
					<Heading
						textAlign="center"
						color="white"
						fontSize="3xl"
						textTransform="uppercase"
					>
						V
					</Heading>
					<Heading
						textAlign="center"
						color="white"
						fontSize="6xl"
						textTransform="uppercase"
					>
						{props.awaygoals}
					</Heading>
				</Flex>
				<Heading
					textAlign="center"
					color="white"
					fontSize="3xl"
					textTransform="uppercase"
				>
					{props.awayteam}
				</Heading>
			</Link>			
		</>
	)
}

export default fixture;