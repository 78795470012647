import React from "react";
import { Formik,  Form } from 'formik';

import { trainingCampUpdate } from "../update";

import { 
    FormControl,
    Button,
    Flex
 } from '@chakra-ui/react'

const trainingCamp = (props) => {
    return(
        <>
            <Formik
            initialValues={{ id: props.TeamId, start_date: Math.floor(Date.now()/1000), date: Math.floor((Date.now())/1000 + 604800)}}
            onSubmit={async (values, actions) => {
                setTimeout(() => {
                    console.log(values)
                    trainingCampUpdate(values)
                }, 500)
              }}
            >
                <Form>
                    <FormControl pt={7}>
                        <Flex alignItems='center'>
                            <Button
                                colorScheme='teal'
                                isLoading={props.isSubmitting}
                                type='submit'
                            >
                                Sign up to training camp
                            </Button>
                        </Flex>
                    </FormControl>
                </Form>
            </Formik>
        </>
    )
}

export default trainingCamp