import React, {Component} from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { Box, Heading, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

class Groups extends Component {
	state = {
		groups : []
	}
	componentWillMount(){
			Axios.get('cup/groups/?id=' + this.props.id)
			.then((groupResponse) => {
				this.setState({groups: groupResponse.data.groups});
			}).catch((error) => {
			})
	}

	render(){
		const groupBy = (array, key) => {
		  return array.reduce((result, currentValue) => {
		    (result[currentValue[key]] = result[currentValue[key]] || []).push(
		      currentValue
		    );
		    return result;
		  }, {});
		};

		const data = groupBy(this.state.groups, 'name');

		const group = () => {
			return(
			Object.keys(data)
				.sort((a, b) => a > b)
				.map(groupName => (
				<>
					<Box>
						<Heading fontSize="2xl" textTransform="uppercase">{groupName}</Heading>
						<TableContainer pb={5}>
							<Table variant='striped' colorScheme='blackAlpha'
								size='lg'>
								<Thead bg='#141414'>
									<Tr>
										<Th color='#ffffff'>Team</Th>
										<Th color='#ffffff'>Played</Th>
										<Th color='#ffffff'>Win</Th>
										<Th color='#ffffff'>Draw</Th>
										<Th color='#ffffff'>Loss</Th>
										<Th color='#ffffff'>For</Th>
										<Th color='#ffffff'>Against</Th>
										<Th color='#ffffff'>Difference</Th>
										<Th color='#ffffff'>Points</Th>
									</Tr>
								</Thead>
								<Tbody>
								{data[groupName]
									.sort(function (team1, team2) {
											if (parseInt(team1.points) > parseInt(team2.points)) return -1;
											if (parseInt(team1.points) < parseInt(team2.points)) return 1;
											if (parseInt((team1.goals - team1.conceded)) > parseInt((team2.goals - team2.conceded))) return -1;
											if (parseInt((team1.goals - team1.conceded)) < parseInt((team2.goals - team2.conceded))) return 1;
											if (parseInt(team1.goals) > parseInt(team2.goals)) return -1;
											if (parseInt(team1.goals) < parseInt(team2.goals)) return 1;
											if (team1.team > team2.team) return 1;
											if (team1.team < team2.team) return -1;
										})
									.map(groupsKey => {
									return(
										<Tr key={groupsKey.id}>
											<Td>{ groupsKey.national == 1 ? <Link to={'/national-teams/' + groupsKey.team_id}>{groupsKey.team}</Link> : <Link to={'/teams/' + groupsKey.team_id}>{groupsKey.team}</Link>}</Td>
											<Td>{Number(groupsKey.wins) + Number(groupsKey.draws) + Number(groupsKey.losses)}</Td>
											<Td>{groupsKey.wins}</Td>
											<Td>{groupsKey.draws}</Td>
											<Td>{groupsKey.losses}</Td>
											<Td>{groupsKey.goals}</Td>
											<Td>{groupsKey.conceded}</Td>
											<Td>{groupsKey.goals - groupsKey.conceded}</Td>
											<Td>{groupsKey.points}</Td>
										</Tr>
									)})}
								</Tbody>
							</Table>
						</TableContainer>
					</Box>
				</>
			))
		)};
		return(
			<>
				{group()}	
			</>
		)
	}
}

export default Groups;