import React from "react";
import { Formik, Field, Form } from 'formik';

import { captainUpdate } from "../update";

import { 
    FormControl,
    FormLabel,
    Select,
    Button,
    Flex
 } from '@chakra-ui/react'

const captainForm = (props) => {
    return(
        <>
            <Formik
            initialValues={{ id: props.TeamId, captainId: '' }}
            onSubmit={async (values, actions) => {
                setTimeout(() => {
                    captainUpdate(values)
                }, 500)
              }}
            >
                <Form>
                    <FormControl pt={7}>
                        <FormLabel>Select Captain</FormLabel>
                        <Flex alignItems='center'>
                            <Field
                                name="captainId"
                            >
                                {({field}) => (
                                    <Select {...field}
                                        placeholder={ props.CaptainId ? props.CaptainFirstname + ' ' + props.CaptainSurname : 'No Captain Selected' }
                                    >
                                    {props.PlayerList.team
                                        .map(player => {
                                            return<option value={player.playerId}>{player.firstname} {player.surname} - ({player.skill} / {player.technique})</option>
                                        })
                                    }
                                    </Select>
                                )}
                            </Field>
                            <Button
                                colorScheme='teal'
                                isLoading={props.isSubmitting}
                                type='submit'
                            >
                                Select Captain
                            </Button>
                        </Flex>
                    </FormControl>
                </Form>
            </Formik>
        </>
    )
}

export default captainForm