import React from 'react';
import Axios from 'axios';
import {Helmet} from "react-helmet";

import { useLocation, useParams } from 'react-router-dom';

import Hero from '../Components/Hero';
import MatchReport from '../Components/Fixtures/MatchReport';
import TeamLineup from '../Components/Fixtures/TeamLineup';
import LineUp from '../Components/Fixtures/Lineup';

import { Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Container,
	Grid,
	GridItem
} from '@chakra-ui/react'


const usePathname = () => {
  const location = useLocation();
  return location.pathname;
}

const Fixture = (props) => {
	const { fixtureId } = useParams();
	const [post, setPost] = React.useState({fixture: null, teamList: null});

	React.useEffect(() => {
		Promise.all([
			Axios.get('fixtures/fixture/?id=' + fixtureId),
			Axios.get('fixtures/fixture/teamlist/?id=' + fixtureId)
		])
		.then(([fixtureResponse, teamListResponse]) => {
		    setPost({ fixture: fixtureResponse.data, teamList: teamListResponse.data.team});
		}, (error) => {
		})

		// eslint-disable-next-line react-hooks/exhaustive-deps
  	}, []);

	const displayData = () => {
	    return post.fixture ? (
	    	<>
	    		<Helmet>
              		<title>{'Websoccer - ' + post.fixture.hometeam + ' vs ' + post.fixture.awayteam}</title>
          		</Helmet>
	       		 <Hero 
						PageType="fixture"
						HomeTeam={post.fixture.hometeam}
						HomeGoals={post.fixture.homegoals}
						AwayTeam={post.fixture.awayteam}
						AwayGoals={post.fixture.awaygoals}
						Crowd={post.fixture.crowd}
						MatchId={ fixtureId }
						TeamList={post.teamList}
						safeMode={props.safeMode}
				/>
				<Container
					maxW='container.3xl'
					pt='75px;'
					pb='75px;'
				>
					<Grid templateColumns={{sm: "100%", lg: "70% 30%"}}>
						<GridItem bgColor="White" pt={20} pb={20} pl={20} pr={10}>
							<Tabs isFitted>
								<TabList>
									<Tab>Commentary</Tab>
									<Tab>Lineups</Tab>
									<Tab>Stats</Tab>
								</TabList>

								<TabPanels>
									<TabPanel>
										<MatchReport
											MatchId={ fixtureId }
											Minutes={post.fixture.minutes}
											HomeTeam={post.fixture.hometeam}
											AwayTeam={post.fixture.awayteam}
										/>
									</TabPanel>
									<TabPanel>
									<LineUp
										MatchId={ fixtureId }
										HomeTeam={post.fixture.hometeam}
										AwayTeam={post.fixture.awayteam}
										TeamList={post.teamList}
										safeMode={props.safeMode}
									/>
									</TabPanel>
									<TabPanel>
									</TabPanel>
								</TabPanels>
							</Tabs>
						</GridItem>
						<GridItem bgColor="gray.100" pt={20} pb={20} pl={10} pr={10}>
							<TeamLineup
								MatchId={ fixtureId }
								HomeTeam={post.fixture.hometeam}
								AwayTeam={post.fixture.awayteam}
								TeamList={post.teamList}
							/>
						</GridItem>
					</Grid>
					
				</Container>
	    	</>
	    ) : (
	    	<>
	    		<Helmet>
	                <title>Websoccer - Loading...</title>
	            </Helmet>
	            <Hero 
					PageType={usePathname}
					HomeTeam=""
					HomeGoals=""
					AwayTeam=""
					AwayGoals=""
					Crowd=""
					MatchId=""
					/>
	    	</>
	    )
	}
	return(
		<>
			{displayData()}
		</>
	)
}

export default Fixture;

