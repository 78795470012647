import React from "react";
import { Formik, Field, Form } from 'formik';

import { transferOffer } from "../update";

import { 
    FormControl,
    FormLabel,
    Select,
    Button,
    Flex,
    Input,
    InputGroup,
    InputLeftAddon
 } from '@chakra-ui/react'

const transferBidForm = (props) => {

    let transferPlaceholder = "Enter Price - Minimum " + props.price
    let price = Number(props.price.replace(/[^0-9.-]+/g,""));
    let wage = Number(props.wage.replace(/[^0-9.-]+/g,""));

    return(
        <>
            <Formik
            initialValues={{ id: props.id, teamId: '1', transferValue: price, wageValue: wage, bonusValue: 0, contractValue: 100 }}
            onSubmit={async (values, actions) => {
                setTimeout(() => {
                    transferOffer(values)
                }, 500)
              }}
            >
                <Form>
                    <FormControl pt={7}>
                        <FormLabel>Select Team</FormLabel>
                        <Flex alignItems='center' mb={2}>
                            <Field
                                name="teamId"
                            >
                                {({field}) => (
                                    <Select {...field}
                                        placeholder={ props.teams ? props.teams.name : 'No Team Selected' }
                                    >
                                    {props.teams
                                        .sort((a, b) => a.name > b.name ? 1 : -1)
                                        .map(team => {
                                            return<option value={team.id}>{team.name}</option>
                                        })
                                    }
                                    </Select>
                                )}
                            </Field>
                        </Flex>
                        <Flex alignItems='center' mb={2}>
                            <Field
                                name="transferValue"
                                mb={1}
                            >
                                {({field}) => (
                                    <InputGroup>
                                        <InputLeftAddon children='£' />
                                        <Input {...field} type='number' placeholder={ transferPlaceholder }/>
                                    </InputGroup>
                                )}
                            </Field>
                        </Flex>
                        <Flex alignItems='center' mb={2}>
                            <Field
                                name="wageValue"
                                mb={1}
                            >
                                {({field}) => (
                                    <InputGroup>
                                        <InputLeftAddon children='£' />
                                        <Input {...field} type='number' placeholder="Enter Wage"/>
                                    </InputGroup>
                                )}
                            </Field>
                        </Flex>
                        <Flex alignItems='center' mb={2}>
                            <Field
                                name="bonusValue"
                                mb={1}
                            >
                                {({field}) => (
                                    <InputGroup>
                                        <InputLeftAddon children='£' />
                                        <Input {...field} type='number' placeholder="Bonus currently unavailable"/>
                                    </InputGroup>
                                )}
                            </Field>
                        </Flex>
                        <Button
                                colorScheme='teal'
                                isLoading={props.isSubmitting}
                                type='submit'
                                mb={10}
                            >
                                Place Bid
                            </Button>
                    </FormControl>
                </Form>
            </Formik>
        </>
    )
}

export default transferBidForm