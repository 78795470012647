import React, {useState, useEffect} from 'react';
import Axios from 'axios';

import RemovePlayer from '../_FormFields/nationalRemove';
import AddPlayer from '../_FormFields/nationalAdd';

import { 
	Box,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	TableCaption,
	TableContainer,
	Td,
	Heading
} from '@chakra-ui/react'

const NationalSelection = (props) => {
	const [post, setPost] = useState({
		players : [],
		availablePlayers : [],
		positions: ['T', 'LV', 'IV', 'RV', 'DM', 'LM', 'ZM', 'RM', 'OM', 'LS', 'MS', 'RS']});

		

		function getPlayers() {
			return Axios.get('national/?id=' + props.Id);
		}
		  
		function getAllPlayers() {
			return Axios.get('player/players/');
		}

	useEffect(() => {

		refreshAvailable();
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function refreshAvailable() {

		Promise.all([getPlayers(), getAllPlayers()])
		.then(function (results) {
			setPost({ 
				players: results[0].data.team,
				availablePlayers : results[1].data,
				positions: post.positions
			});
		});
	  }

	return(
		<>
			<Box boxShadow='md' bg='white' w='100%' p={20}>
				<Tabs isLazy>
					<TabList>
						<Tab>All</Tab>
						<Tab>Goalkeepers</Tab>
						<Tab>Left Defence</Tab>
						<Tab>Central Defence</Tab>
						<Tab>Right Defence</Tab>
						<Tab>Defensive Midfield</Tab>
						<Tab>Left Midfield</Tab>
						<Tab>Central Midfield</Tab>
						<Tab>Right Midfield</Tab>
						<Tab>Attacking Midfield</Tab>
						<Tab>Left Forward</Tab>
						<Tab>Centre Forward</Tab>
						<Tab>Right Forward</Tab>
					</TabList>
					<TabPanels>
						<TabPanel>
							<TableContainer>
								<Table variant='striped' colorScheme='gray.100'>
									<TableCaption>All Players</TableCaption>
									<Thead bg="blackAlpha.800">
										<Tr>
											<Th color="white">Remove</Th>
											<Th color="white">Name</Th>
											<Th color="white">Position</Th>
											<Th color="white">Main Position</Th>
											<Th color="white">Skill</Th>
											<Th color="white">Technique</Th>
											<Th color="white">Fitness</Th>
											<Th color="white">Stamina</Th>
											<Th color="white">Morale</Th>
											<Th color="white">Injured</Th>
											<Th color="white">Suspended</Th>
										</Tr>
									</Thead>
									<Tbody>
									{post.players
										.sort((a, b) => a.skill < b.skill)
										.map(playerKey => {
											let PositionTranslate = playerKey.position
											if (playerKey.position === "Torwart"){
												PositionTranslate = "Goalkeeper"
											} else if (playerKey.position === "Abwehr"){
													PositionTranslate = "Defender"
											} else if (playerKey.position === "Mittelfeld"){
													PositionTranslate = "Midfielder"
											} else if (playerKey.position === "Sturm"){
													PositionTranslate = "Forward"
											}
											let SecondPositionTranslate = playerKey.mainPosition
											if (playerKey.mainPosition === "T"){
													SecondPositionTranslate = "Goalkeeper"
												} else if (playerKey.mainPosition === "IV"){
													SecondPositionTranslate = "Central Defence"
												} else if (playerKey.mainPosition === "LV"){
													SecondPositionTranslate = "Left Back"
												} else if (playerKey.mainPosition === "RV"){
													SecondPositionTranslate = "Right Back"
												} else if (playerKey.mainPosition === "DM"){
													SecondPositionTranslate = "Defensive Midfield"
												} else if (playerKey.mainPosition === "RM"){
													SecondPositionTranslate = "Right Midfield"
												} else if (playerKey.mainPosition === "LM"){
													SecondPositionTranslate = "Left Midfield"
												} else if (playerKey.mainPosition === "OM"){
													SecondPositionTranslate = "Attacking Midfield"
												} else if (playerKey.mainPosition === "ZM"){
													SecondPositionTranslate = "Centre Midfield"
												} else if (playerKey.mainPosition === "LS"){
													SecondPositionTranslate = "Left Forward"
												} else if (playerKey.mainPosition === "MS"){
													SecondPositionTranslate = "Centre Forward"
												} else if (playerKey.mainPosition === "RS"){
													SecondPositionTranslate = "Right Forward"
												}
											return (
												<>
												<Tr key={ playerKey.playerId } backgroundColor={playerKey.injured > 0 || playerKey.nt_suspension > 0 ? 'red.400' : 'transparent'}>
													<Td>
														<RemovePlayer
															PlayerId = { playerKey.playerId }
															buttonClick={() => refreshAvailable()}
														/>
													</Td>
													<Td>{playerKey.firstname} {playerKey.surname}</Td>
													<Td>{PositionTranslate}</Td>
													<Td>{playerKey.mainposition ? playerKey.mainposition : SecondPositionTranslate}</Td>
													<Td>{playerKey.skill}</Td>
													<Td>{playerKey.technique}</Td>
													<Td>{playerKey.fitness}</Td>
													<Td>{playerKey.stamina}</Td>
													<Td>{playerKey.morale}</Td>
													<Td>{playerKey.injured}</Td>
													<Td>{playerKey.nt_suspension}</Td>
												</Tr>
												</>
											)
										})}
										
										
													
													

									</Tbody>
								</Table>
							</TableContainer>
						</TabPanel>
						{post.positions
							.map(positionKey => {
								let mainPosition = ''
								if (positionKey === 'T') {
									mainPosition = 'Torwart';
								} else if (positionKey === 'LV' || positionKey === 'IV' || positionKey === 'RV') {
									mainPosition = 'Abwehr'
								} else if (positionKey === 'DM' || positionKey === 'LM' || positionKey === 'ZM' || positionKey === 'RM' || positionKey === 'OM') {
									mainPosition = 'Mittelfeld'
								} else if (positionKey === 'LS' || positionKey === 'MS' || positionKey === 'RS') {
									mainPosition = 'Sturm'
								}
								return(		
								<TabPanel>
									<TableContainer>
										<Table variant='striped' colorScheme='gray.100'>
											<TableCaption>All Players</TableCaption>
											<Thead bg="blackAlpha.800">
												<Tr>
													<Th color="white">Remove</Th>
													<Th color="white">Name</Th>
													<Th color="white">Position</Th>
													<Th color="white">Main Position</Th>
													<Th color="white">Skill</Th>
													<Th color="white">Technique</Th>
													<Th color="white">Fitness</Th>
													<Th color="white">Stamina</Th>
													<Th color="white">Morale</Th>
													<Th color="white">Injured</Th>
													<Th color="white">Suspended</Th>
												</Tr>
											</Thead>
											<Tbody>
											{post.players
												.filter(playerKey => (playerKey.mainPosition === positionKey || (playerKey.mainPosition === null && playerKey.position === mainPosition)))
												.sort((a, b) => a.skill < b.skill)
												.map(playerKey => {
													let PositionTranslate = playerKey.position
													if (playerKey.position === "Torwart"){
														PositionTranslate = "Goalkeeper"
													} else if (playerKey.position === "Abwehr"){
															PositionTranslate = "Defender"
													} else if (playerKey.position === "Mittelfeld"){
															PositionTranslate = "Midfielder"
													} else if (playerKey.position === "Sturm"){
															PositionTranslate = "Forward"
													}
													let SecondPositionTranslate = playerKey.mainPosition
													if (playerKey.mainPosition === "T"){
															SecondPositionTranslate = "Goalkeeper"
														} else if (playerKey.mainPosition === "IV"){
															SecondPositionTranslate = "Central Defence"
														} else if (playerKey.mainPosition === "LV"){
															SecondPositionTranslate = "Left Back"
														} else if (playerKey.mainPosition === "RV"){
															SecondPositionTranslate = "Right Back"
														} else if (playerKey.mainPosition === "DM"){
															SecondPositionTranslate = "Defensive Midfield"
														} else if (playerKey.mainPosition === "RM"){
															SecondPositionTranslate = "Right Midfield"
														} else if (playerKey.mainPosition === "LM"){
															SecondPositionTranslate = "Left Midfield"
														} else if (playerKey.mainPosition === "OM"){
															SecondPositionTranslate = "Attacking Midfield"
														} else if (playerKey.mainPosition === "ZM"){
															SecondPositionTranslate = "Centre Midfield"
														} else if (playerKey.mainPosition === "LS"){
															SecondPositionTranslate = "Left Forward"
														} else if (playerKey.mainPosition === "MS"){
															SecondPositionTranslate = "Centre Forward"
														} else if (playerKey.mainPosition === "RS"){
															SecondPositionTranslate = "Right Forward"
														}
													return (
														<>
														<Tr key={playerKey.id} backgroundColor={playerKey.injured > 0 || playerKey.nt_suspension > 0 ? 'red.400' : 'transparent'}>
															<Td>
																<RemovePlayer
																	PlayerId = { playerKey.playerId }
																	buttonClick={() => refreshAvailable()}
																/>
															</Td>
															<Td>{playerKey.firstname} {playerKey.surname}</Td>
															<Td>{PositionTranslate}</Td>
															<Td>{playerKey.mainposition ? playerKey.mainposition : SecondPositionTranslate}</Td>
															<Td>{playerKey.skill}</Td>
															<Td>{playerKey.technique}</Td>
															<Td>{playerKey.fitness}</Td>
															<Td>{playerKey.stamina}</Td>
															<Td>{playerKey.morale}</Td>
															<Td>{playerKey.injured}</Td>
															<Td>{playerKey.nt_suspension}</Td>
														</Tr>
														</>
													)
												})}
												<Tr>
													<Th colSpan='11' textAlign='center'><Heading fontSize='md'>Select Players</Heading></Th>
												</Tr>
												{post.availablePlayers
												.filter(playerKey => (playerKey.position_main === positionKey || (playerKey.position_main === null && playerKey.position === mainPosition)) && (playerKey.nationality === props.Name ) && (playerKey.nationalplayer === null ))
												.sort((a, b) => a.skill < b.skill)
												.map(playerKey => {
													let PositionTranslate = playerKey.position
													if (playerKey.position === "Torwart"){
														PositionTranslate = "Goalkeeper"
													} else if (playerKey.position === "Abwehr"){
															PositionTranslate = "Defender"
													} else if (playerKey.position === "Mittelfeld"){
															PositionTranslate = "Midfielder"
													} else if (playerKey.position === "Sturm"){
															PositionTranslate = "Forward"
													}
													let SecondPositionTranslate = playerKey.mainPosition
													if (playerKey.mainPosition === "T"){
															SecondPositionTranslate = "Goalkeeper"
														} else if (playerKey.mainPosition === "IV"){
															SecondPositionTranslate = "Central Defence"
														} else if (playerKey.mainPosition === "LV"){
															SecondPositionTranslate = "Left Back"
														} else if (playerKey.mainPosition === "RV"){
															SecondPositionTranslate = "Right Back"
														} else if (playerKey.mainPosition === "DM"){
															SecondPositionTranslate = "Defensive Midfield"
														} else if (playerKey.mainPosition === "RM"){
															SecondPositionTranslate = "Right Midfield"
														} else if (playerKey.mainPosition === "LM"){
															SecondPositionTranslate = "Left Midfield"
														} else if (playerKey.mainPosition === "OM"){
															SecondPositionTranslate = "Attacking Midfield"
														} else if (playerKey.mainPosition === "ZM"){
															SecondPositionTranslate = "Centre Midfield"
														} else if (playerKey.mainPosition === "LS"){
															SecondPositionTranslate = "Left Forward"
														} else if (playerKey.mainPosition === "MS"){
															SecondPositionTranslate = "Centre Forward"
														} else if (playerKey.mainPosition === "RS"){
															SecondPositionTranslate = "Right Forward"
														}
													return (
														<>
														<Tr key={playerKey.id} backgroundColor={playerKey.injured > 0 || playerKey.nt_suspension > 0 ? 'red.400' : 'transparent'}>
															<Td>
																<AddPlayer
																	PlayerId = { playerKey.id }
																	TeamId = { props.Id }
																	buttonClick={() => refreshAvailable()}
																/>
															</Td>
															<Td>{playerKey.firstname} {playerKey.surname}</Td>
															<Td>{PositionTranslate}</Td>
															<Td>{playerKey.mainposition ? playerKey.mainposition : SecondPositionTranslate}</Td>
															<Td>{playerKey.skill}</Td>
															<Td>{playerKey.technique}</Td>
															<Td>{playerKey.fitness}</Td>
															<Td>{playerKey.stamina}</Td>
															<Td>{playerKey.morale}</Td>
															<Td>{playerKey.injured}</Td>
															<Td>{playerKey.nt_suspension}</Td>
														</Tr>
														</>
													)
												})}
															
															

											</Tbody>
										</Table>
									</TableContainer>
								</TabPanel>
								)
							})
						}
					</TabPanels>
				</Tabs>
			</Box>
		</>
	)
}

export default NationalSelection;