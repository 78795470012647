import React, {Component} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { createBrowserHistory } from "history";
import {connect} from 'react-redux';
import * as actions from './store/actions/index';
import ScrollToTop from './hoc/Scroll';

import { extendTheme, ChakraProvider, Box } from '@chakra-ui/react';

import Auth from './Pages/Login';
import Logout from './Components/logout';
import Navigation from './Components/Layout';
import Home from './Pages/Home';
import NewsHome from './Pages/News';
import Article from './Pages/News/Article';
import League from './Pages/Leagues';
import Table from './Pages/Leagues/League';
import Cup from './Pages/Cup';
import Cups from './Pages/Cup/Cup';
import Teams from './Pages/Teams';
import Team from './Pages/Teams/Team';
import Fixture from './Pages/Fixture';
import Player from './Pages/Player';
import Transfer from './Pages/Transfer';
import National from './Pages/National';
import NationalTeam from './Pages/Teams/NationalTeam';
import Lost from './Pages/404';

const theme = extendTheme({ 
  fonts: {
    body: "elza-text, sans-serif",
    heading: "bebas-neue-pro, sans-serif"
  },
  sizes: {
    container: {
      xxl   : '1400px',
      '3xl' : '1600px',
    },
  }
  
})


class App extends Component {

  constructor(props){
    super(props)
     
    // Set initial state
    this.state = {safeMode: 1}
     
    // Binding this keyword
    this.smChange = this.smChange.bind(this)
  }

  componentDidMount () {
    this.props.onTryAutoSignup();
  }

  smChange(){
    this.state.safeMode == 1 ? this.setState({safeMode: 0}) : this.setState({safeMode: 1})
  }


  render(){

    const history = createBrowserHistory();

    let routes = null

    if (this.props.isAuthenticated) {
      routes = (
          <Routes history={history}>
            <Route path="/player/:playerId" element={<Player  safeMode={this.state.safeMode}/>}/>
            <Route path="/teams" exact element={<Teams  safeMode={this.state.safeMode}/>}/>
            <Route path="/teams/:teamId" element={<Team  safeMode={this.state.safeMode}/>}/>
            <Route path="/fixture/:fixtureId" element={<Fixture  safeMode={this.state.safeMode}/>}/>
            <Route path="/national-teams" element={<National  safeMode={this.state.safeMode}/>}/>
            <Route path="/national-teams/:teamId" element={<NationalTeam  safeMode={this.state.safeMode}/>}/>
            <Route path="/league" exact element={<League  safeMode={this.state.safeMode}/>}/>
            <Route path="/league/:leagueId" element={<Table  safeMode={this.state.safeMode}/>}/>
            <Route path="/cup/" exact element={<Cups  safeMode={this.state.safeMode}/>}/>
            <Route path="/cup/:cupId" element={<Cup  safeMode={this.state.safeMode}/>}/>
            <Route path="/news" exact element={<NewsHome safeMode={this.state.safeMode}/>}/>
            <Route path="/news/:articleId" exact element={<Article safeMode={this.state.safeMode}/>}/>
            <Route path="/Transfer" exact element={<Transfer  safeMode={this.state.safeMode}/>}/>
            <Route path="/logout" exact element={<Logout/>}/>
            <Route path="/" exact element={<Home safeMode={this.state.safeMode}/>}/>
            <Route element={<Lost/>}/>
          </Routes>
      );
    };
    return (
      <React.Fragment>
        <ChakraProvider theme={theme}>
          <BrowserRouter>
          <ScrollToTop />
            <div>
              {this.props.isAuthenticated ? <Navigation/> : <Auth/>}
              {routes}
              <Box bg='teal.200' w='100%' p={4} color='black' onClick={this.smChange}>
                SM
              </Box>
            </div>
          </BrowserRouter>
        </ChakraProvider>
      </React.Fragment>
    );
  }
  
}

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  }
}

const mapStateToProps = state =>{
  return {
    isAuthenticated: state.auth.token !== null
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
