import React from "react";
import { Formik, Form, Field } from 'formik';

import { contractPlayer } from "../update";

import { 
    FormControl,
    FormLabel,
    Heading,
    Button,
    Input,
    InputGroup,
    InputLeftAddon
 } from '@chakra-ui/react'

const extendContract = (props) => {

    return(
        <>
            <Formik
            initialValues={{ playerId: props.PlayerId, wageOffer: '' }}
            onSubmit={async (values, actions) => {
                setTimeout(() => {
                    contractPlayer(values)
                }, 500)
              }}
            >
                <Form>
                    <FormControl>
                        <FormLabel>
                            <Heading fontSize="xl" textTransform='uppercase' pt={5}>
                                Contract Extensions
                            </Heading>
                        </FormLabel>
                        <Field
                            name="wageOffer"
                        >
                            {({field}) => (
                                <InputGroup>
                                    <InputLeftAddon children='£' />
                                    <Input {...field} type='number' placeholder='Enter new wage'/>
                                </InputGroup>
                            )}
                        </Field>
                        <Button
                            colorScheme='teal'
                            isLoading={props.isSubmitting}
                            type='submit'
                            mt={5}
                        >
                            Extend Contract
                        </Button>
                    </FormControl>
                </Form>
            </Formik>
        </>
    )
}

export default extendContract