import React, { useState } from 'react';
import {Helmet} from "react-helmet";
import Axios from 'axios';

import { Link, useLocation, useParams } from 'react-router-dom';

import Hero from '../../Components/Hero';

import { Container,
	Grid,
	GridItem,
	Box,
	Card,
	AspectRatio,
	Image,
	CardBody,
	Heading
 } from '@chakra-ui/react'

const usePathname = () => {
  const location = useLocation();
  return location.pathname;
}

const Article = (props) => {
	const { articleId } = useParams();

	const [data, setData] = useState({ article: [], articles: [] });

	React.useEffect(() => {
		Promise.all([
			Axios.get('news/articles/?id=' + articleId),
			Axios.get('news/')
		])
		.then(([articleResponse, articlesResponse]) => {
			setData({article: articleResponse.data, articles: articlesResponse.data});
		}, (error) => {
		})
	}, []);

	if (!data) return null;

	return(
		<>
		<Helmet>
            <title>Websoccer - </title>
        </Helmet>
        <Hero
        	HeroImage1={data.article.image}
        	FirstName={data.article.title}
        	PageType={usePathname}
			safeMode={props.safeMode}
        />
		<Container
			maxW='container.3xl'
			pt='75px;'
			pb='75px;'
		>
			<Grid templateColumns={{base: '100%', lg:'70% 30%' }} gap={6}>
				<GridItem w='100%'>
					<Box boxShadow='md' bg='white' w='100%' p={20}>
					<div dangerouslySetInnerHTML={{__html : data.article.content}}></div>
					</Box>
				</GridItem>
				<GridItem w='100%'>
					{data.articles
							.sort(({date: previousDate}, {date: currentDate}) => currentDate -previousDate)
							.slice(0, 3)
							.map(articleKey => {
							return(
								<Link key={articleKey.id} to={'/news/' + articleKey.id}>
									<Card mb='50px'>
										<AspectRatio ratio={4 / 3}>
											{props.safeMode == 1 ?
												<Image src="https://images2.imgbox.com/32/ff/MwCs8AHw_o.jpg"
												alt={articleKey.title} />
												: <Image src={articleKey.image}
													alt={articleKey.title} />
											}
										</AspectRatio>
										<CardBody>
											<Heading
												textTransform='uppercase'
											>
												{articleKey.title}
											</Heading>
										</CardBody>
									</Card>
								</Link>
							)
						})}
				</GridItem>
			</Grid>
		</Container>
        </>
	)
}

export default Article;