import React, {Component, useState, useEffect} from 'react';
import Axios from 'axios';

import {  
	GridItem,
	Text
} from '@chakra-ui/react'

const Scorers = (props) => {
	const [data, setData] = useState({ matchreport: [], title: 'Teams' });

	useEffect(() => {
			Promise.all([
				Axios.get('fixtures/fixture/report/?id=' + props.MatchId)
			])
			.then(([reportResponse]) => {
				setData({ matchreport: reportResponse.data.match, title: 'Teams'});
			}, (error) => {
			})
	}, []);

	const home_scorer = Object.keys(data.matchreport)
						.map((playerKey, i) => {
							return [...Array(data.matchreport[playerKey])]
								.filter(_ => (_.active_team == '1'  && _.message_action == 'Tor_mit_vorlage') ||  (_.active_team == '1'  &&  _.message_action == 'Tor') ||  (_.active_team == '1'  && _.message_action == 'Freistoss_treffer') ||  (_.active_team == '1'  && _.message_action == 'Elfmeter_erfolg' ))
								.map((_, i) => {
								const names = _.playernames.split(';');
								return <><Text textAlign="right" textTransform="capitalize" key={i}>{names[0]} <Text display="inline-block" color="teal.200" w={8}>{_.minute}'</Text></Text></>
							})
						});
	const away_scorer = Object.keys(data.matchreport)
					.map((playerKey, i) => {
						return [...Array(data.matchreport[playerKey])]
							.filter(_ => (_.active_team == '0'  && _.message_action == 'Tor_mit_vorlage') ||  (_.active_team == '0'  &&  _.message_action == 'Tor') ||  (_.active_team == '0'  && _.message_action == 'Freistoss_treffer') ||  (_.active_team == '0'  && _.message_action == 'Elfmeter_erfolg'))
							.map((_, i) => {
							const names = _.playernames.split(';');
							return <><Text textAlign="left" textTransform="capitalize" key={i}><Text display="inline-block" color="teal.200" w={8}>{_.minute}'</Text>{names[0]}</Text></>
						})
					});
	
	const home_assist = () => {
		return props.TeamList ? (Object.keys(props.TeamList)
		.map((playerKey, i) => {
			return [...Array(props.TeamList[playerKey])]
				.filter(_ => (parseInt(_.assists) > 0) && (_.team == props.HomeTeam))
				.map((_, i) => {
				return  <><Text textAlign="right" textTransform="capitalize" key={i}>{_.player_name} {_.assists > 1 ? 'x' + _.assists : null}</Text></>
			})
		})
		) : (
			<></>
		)
	}

	const away_assist = () => {
		return props.TeamList ? (Object.keys(props.TeamList)
		.map((playerKey, i) => {
			return [...Array(props.TeamList[playerKey])]
				.filter(_ => (parseInt(_.assists) > 0) && (_.team == props.AwayTeam))
				.map((_, i) => {
				return <><Text textAlign="left" textTransform="capitalize" key={i}>{_.player_name} {_.assists > 1 ? 'x' + _.assists : null}</Text></>
			})
		})
		) : (
			<></>
		)
	}

		const displayData = () => {
			return data ? (
				<>
					<GridItem  alignSelf="start">
						{home_scorer}
					</GridItem>
					<GridItem colSpan={3}>
						
					</GridItem>
					<GridItem  alignSelf="start">
						{away_scorer}
					</GridItem>
					<GridItem colSpan={5}>
						<Text>Assists</Text>
					</GridItem>
					<GridItem  alignSelf="start">
						{home_assist()}
					</GridItem>
					<GridItem colSpan={3}>
						
					</GridItem>
					<GridItem  alignSelf="start">
						{away_assist()}
					</GridItem>
				</>
			) : (
				<>
					
				</>
			)
		}
	
		return(
			<>
				{displayData()}
			</>
		)
}

export default Scorers;