import React from 'react';
import Axios from 'axios';
import {Helmet} from "react-helmet";

import Hero from '../../Components/Hero';
import LeagueTable from '../../Components/LeagueTable/LeagueTable'
import Scorers from '../../Components/LeagueTable/Scorers'
import History from '../../Components/League/History'

import { useLocation, useParams } from 'react-router-dom';

const usePathname = () => {
  const location = useLocation();
  return location.pathname;
}


const League = (props) => {
	const { leagueId } = useParams();
	const [post, setPost] = React.useState({league: null, season: null, leagueTeams: null, promotion: null, history: null});

	const initialCall = () => {
		Promise.all([
			Axios.get('league/?id=' + leagueId),
			Axios.get('season/?id=' + leagueId),
			Axios.get('league/teams/?id=' + leagueId),
			Axios.get('league/history/?id=' + leagueId)
		])
		.then(([teamResponse, seasonResponse, teamsResponse, historyResponse]) => {
		    setPost({ league: teamResponse.data, season: seasonResponse.data.season[0].id, leagueTeams: teamsResponse.data.team, promotion: null, history: historyResponse.data.season});
		    Axios.get('league/promotion/?id=' + leagueId).then((promotionResponse) => {
		    	setPost({ league: teamResponse.data, season: seasonResponse.data.season[0].id, leagueTeams: teamsResponse.data.team, promotion: promotionResponse.data.promotion, history: historyResponse.data.season});
		    }, (error) => {
				})
		}, (error) => {
		})
	}

	React.useEffect(() => {
		initialCall();
  	}, []);

	const displayData = () => {
	    return post.league ? (
	      	<>
	      		<Helmet>
	            	<title>Websoccer - {post.league.name}</title>
	        	</Helmet>
	        	<Hero 
	        		FirstName={post.league.name}
	        		PageType={usePathname}
					safeMode={props.safeMode}/>
	        	<LeagueTable 
	        		Id={ leagueId }
	        		Teams={ post.leagueTeams }
	        		PromotionDetails={ post.promotion }/>
	        	<Scorers 
					safeMode={props.safeMode}
	        		Id={ leagueId }/>
				<History 
	        		Id={ leagueId }
					History={ post.history }/>
	        </>
	    ) : (
	    	<>
	      		<Helmet>
	            	<title>Websoccer - Loading...</title>
	        	</Helmet>
	        	<Hero 
	        		FirstName="Loading..."
	        		PageType={usePathname}/>
	        	<LeagueTable />
	        	<Scorers />
				<History />
	        </>
	    );
	 }

	return(
		<>
			{displayData()}
        </>
	)
}

export default League;