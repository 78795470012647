import React from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

import { useLocation, useParams } from 'react-router-dom';

import Hero from '../Components/Hero';
import CupRound from '../Components/Cup/Rounds';
import Scorers from '../Components/Cup/Scorers'

import { 
	Heading,
	Container,
	Box
} from '@chakra-ui/react'

const usePathname = () => {
  const location = useLocation();
  return location.pathname;
}

const Cup = (props) => {
	const { cupId } = useParams();
	const [post, setPost] = React.useState(null);

	React.useEffect(() => {
		Promise.all([
			Axios.get('cup/?id=' + cupId)
		])
		.then(([cupResponse]) => {
		    setPost({ cup: cupResponse.data});
		}, (error) => {
		})
  	}, []);

	const displayData = () => {
	    return post ? (
	    	<>
	    		<Helmet>
	                <title>Websoccer - { post.cup.name }</title>
	            </Helmet>
	            <Hero 
					FirstName={ post.cup.name }
					PageType={ usePathname }
					safeMode={props.safeMode} />
					{post.cup.archived === '1' ?<div className="grid-container">
						<div className="grid-x grid-padding-x align-center text-center">
							<div className="cell small-8 text-center">
								<Link to={'/teams/' + post.cup.winner_id}>
									<div className="date">
										<h2>Winner</h2>
									</div>
									<h1>{post.cup.team}</h1>
								</Link>
							</div>
						</div>
					</div>
					: <>
						<CupRound
							id={cupId}/>
					</>}
				<Scorers 
					Id={ cupId }
					safeMode={props.safeMode}
				/>
	    	</>
	    ) : (
	    	<>
	    		<Helmet>
	                <title>Websoccer - Loading...</title>
	            </Helmet>
	            <Hero 
					FirstName="Loading..."
					PageType={usePathname} />

				<Container
						maxW='container.3xl'
						pt='75px;'
						pb='75px;'
					>
					<Box boxShadow='md' bg='white' w='100%' p={20}>
						<Heading size="2xl" textTransform="uppercase">No Cup Found</Heading>
					</Box>
				</Container>
				<Scorers />
	    	</>
	    )
	 }

	return(
		<>
			{displayData()}
		</>
	)
}
export default Cup;
/*
class Cup extends Component {
	state = {
		id: this.props.match.params.cupId,
		cup:[]
	}
	componentWillMount(){
		Axios.get('cup/?id=' + this.state.id)
			.then(cupResponse => {
				this.setState({cup: cupResponse.data});
			}).catch((error) => {
				console.log(error);
			})
	}
	render(){
		let cup = null

		if (this.state.cup.archived === "1"){
			cup = <div className="grid-container">
						<div className="grid-x grid-padding-x align-center text-center">
							<div className="cell small-8 small-offset-2 fixture">
								<Link to={'/teams/' + this.state.cup.winner_id}>
									<div className="date">
										<h2>Winner</h2>
									</div>
									<h1>{this.state.cup.team}</h1>
								</Link>
							</div>
						</div>
					</div>
		} else {
			cup = <CupRound
					id={this.state.id}/>
		}
		return(
			<React.Fragment>
				<Helmet>
	                <title>{'Websoccer - ' + this.state.cup.name}</title>
	            </Helmet>
				<Hero 
					FirstName={this.state.cup.name}
					PageType={this.props.match.path} />
				{cup}
				<Scorers
					Id={this.state.id}/>
			</React.Fragment>
		)
	}
}

*/
