import React, {Component} from 'react';
import Axios from 'axios';

class NationDetails extends Component{
	state = {
		stats:[]
	}
	componentWillMount(){
		Promise.all([
			Axios.get('player/playerStats/?id=' + this.props.Id)
		])
			.then(([statsResponse]) => {
				this.setState({stats: statsResponse.data.match});
			});
	}

	render(){
		const totalGames = this.state.stats.filter(nationKey => {
			return nationKey.national === '1'
		}).length;
		const totalGoals = this.state.stats.filter(nationKey => {
			return nationKey.national === '1'
		}).reduce((a, currValue) => parseFloat(a) + parseFloat(currValue.goals), 0);

		return(
			<span><strong>{totalGames}</strong> Caps / <strong>{totalGoals}</strong> Goals</span>
		);
	}
}

export default NationDetails