import React from 'react';
import {Helmet} from "react-helmet";

const Lost = () => {
	return(
		<React.Fragment>
			<Helmet>
	                <title>Websoccer</title>
	        </Helmet>
			<div className="h100 grid-x align-middle align-center">
				<div className="cell auto text-center">
					<h1>404</h1>
					<h2>Page not Found</h2>
				</div>
			</div>
		</React.Fragment>
	)
}

export default Lost