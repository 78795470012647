import React, {useState } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

import Hero from '../Components/Hero';

import { Grid, 
	GridItem, 
	Container, 
	Image, 
	AspectRatio, 
	Card,
	CardBody,
	Heading
} from '@chakra-ui/react'

const News = (props) => {

	const [data, setData] = useState({ articles: [], title: 'News' });

	React.useEffect(() => {
			Promise.all([
				Axios.get('news/')
			])
			.then(([newsResponse]) => {
				setData({ articles : newsResponse.data, title: 'News'});
			}, (error) => {
			})
	}, []);

	const displayData = () => {
	    return data.articles ? (
			<>
				<Helmet>
					<title>Websoccer - News</title>
				</Helmet>
				<Hero 
					FirstName={data.title}
					PageType="news"
					safeMode={props.safeMode} />
				<Container
					maxW='container.3xl'
					pt='75px;'
					pb='75px;'
				>
					<Grid
						templateColumns={{ base : 'repeat(1, 1fr)', md : 'repeat(2, 1fr)', xl: 'repeat(3, 1fr)'}}
						gap={6}
					>
						{data.articles
							.sort(({date: previousDate}, {date: currentDate}) => currentDate -previousDate)
							.map(articleKey => {
							return(
								<GridItem>
									<Link key={articleKey.id} to={'/news/' + articleKey.id}>
										<Card>
											<AspectRatio ratio={4 / 3}>
												{props.safeMode == 1 ?
													<Image src="https://images2.imgbox.com/32/ff/MwCs8AHw_o.jpg"
													alt={articleKey.title} />
													: <Image src={articleKey.image}
														alt={articleKey.title} />
												}
											</AspectRatio>
											<CardBody>
												<Heading
													textTransform='uppercase'
												>
													{articleKey.title}
												</Heading>
											</CardBody>
										</Card>
									</Link>
									
								</GridItem>
							)
						})}
					</Grid>
				</Container>
			</>
		) : (
			<>
				<Helmet>
					<title>Websoccer - News</title>
				</Helmet>
				<Hero 
					FirstName=""
					PageType="news" />
			</>
		)
	}

	return(
		<>
			{displayData()}
		</>
	)
}

export default News;