import React from 'react';
import Axios from 'axios';
import {Helmet} from "react-helmet";

import {useLocation, useParams } from 'react-router-dom';

import Hero from '../Components/Hero';
import Content from '../Components/Player/Content';
import PlayerStats from '../Components/Player/PlayerStats';
import FullStats from '../Components/Player/FullStats';
import CupStats from '../Components/Player/CupStats';

import {
	Box,
} from '@chakra-ui/react'

const usePathname = () => {
  const location = useLocation();
  return location.pathname;
}

const Player = (props) => {
	const { playerId } = useParams();
	const [post, setPost] = React.useState({player: null});

	React.useEffect(() => {
		Promise.all([
			Axios.get('player/?id=' + playerId)
		])
		.then(([playerResponse]) => {
		    setPost({ player : playerResponse.data});
		}, (error) => {
		})
  }, []);

	const PositionTranslate = () => {
		return post.player ? (
			post.player.position === "Torwart" ? "Goalkeeper"
			: post.player.position === "Abwehr" ? "Defender"
			: post.player.position === "Mittelfeld" ? "Midfielder"
			: post.player.position === "Sturm" ? "Forward"
			: null
		) : (
			null
		)
	}

	const PositionMainTranslate = () => {
		return post.player ? (
			post.player.position_main === "T" ? "Goalkeeper"
			: post.player.position_main === "LV" ? "Left Back"
			: post.player.position_main === "IV" ? "Centre Back"
			: post.player.position_main === "RV" ? "Right Back"
			: post.player.position_main === "LM" ? "Left Midfield"
			: post.player.position_main === "DM" ? "Defensive Midfield"
			: post.player.position_main === "ZM" ? "Centre Midfield"
			: post.player.position_main === "RM" ? "Right Midfield"
			: post.player.position_main === "OM" ? "Attacking Midfield"
			: post.player.position_main === "LS" ? "Left Forward"
			: post.player.position_main === "MS" ? "Striker"
			: post.player.position_main === "RS" ? "Right Forward"
			: null
		) : (
			null
		)
	}

	const PositionSecondaryTranslate = () => {
		return post.player ? (
			post.player.position_secondary === "T" ? "Goalkeeper"
			: post.player.position_secondary === "LV" ? "Left Back"
			: post.player.position_secondary === "IV" ? "Centre Back"
			: post.player.position_secondary === "RV" ? "Right Back"
			: post.player.position_secondary === "LM" ? "Left Midfield"
			: post.player.position_secondary === "DM" ? "Defensive Midfield"
			: post.player.position_secondary === "ZM" ? "Centre Midfield"
			: post.player.position_secondary === "RM" ? "Right Midfield"
			: post.player.position_secondary === "OM" ? "Attacking Midfield"
			: post.player.position_secondary === "LS" ? "Left Forward"
			: post.player.position_secondary === "MS" ? "Striker"
			: post.player.position_secondary === "RS" ? "Right Forward"
			: null
		) : (
			null
		)
	}

	const displayData = () => {
	    return post.player ? (
	    	<>
	    		<Helmet>
	                <title>{'Websoccer - ' + post.player.firstname + ' ' + post.player.surname}</title>
	            </Helmet>
	            <Hero 
					HeroImage1={post.player.hero_url}
					HeroImage2={post.player.hero_url2}
					HeroImage3={post.player.hero_url3}
					FirstName={post.player.firstname}
					Surname={post.player.surname}
					Psuedoname={post.player.psuedoname}
					TotalGames={post.player.played}
					TotalGoals={post.player.goals}
					TotalAssists={post.player.assists}
					TotalYC={post.player.yellow_cards}
					TotalRC={post.player.red_cards}
					PageType="player"
					safeMode={props.safeMode}/>
				<Content 
					Id={post.player.id}
					FirstName={post.player.firstname}
					Surname={post.player.surname}
					Position={PositionTranslate()}
					Image1={post.player.image_url}
					Image2={post.player.image_url2}
					Image3={post.player.image_url3}
					Image4={post.player.image_url4}
					Image5={post.player.image_url5}
					Nationality={post.player.nation}
					PositionMain={PositionMainTranslate()}
					PositionSecondary={PositionSecondaryTranslate()}
					Age={post.player.age}
					Wages={post.player.wages}
					GoalBonus={post.player.goal_bonus}
					Contract={post.player.contract}
					Value={post.player.value}
					Team={post.player.team}
					TeamId={post.player.teamId}
					Skill={post.player.skill}
					Technique={post.player.technique}
					Stamina={post.player.stamina}
					Fitness={post.player.fitness}
					Morale={post.player.morale}
					History={post.player.history}
					Injured={post.player.injured}
					Suspended={post.player.suspension}
					SuspendedCup={post.player.suspension_cup}
					TransferListed={post.player.transfer_listed}
					DomesticWins={post.player.domestic_winner}
					DomesticRunnerUp={post.player.domestic_runnerup}
					DomesticMostGoals={post.player.domestic_topgoalscorer}
					DomesticMostAssists={post.player.domestic_mostassists}
					DomesticCupWins={post.player.domesticcup_winner}
					DomesticCupRunnerUp={post.player.domesticcup_runnerup}
					DomesticCupMostGoals={post.player.domesticcup_topgoalscorer}
					DomesticCupMostAssists={post.player.domesticcup_mostassists}
					EuropeanWins={post.player.european_winner}
					EuropeanRunnerUp={post.player.european_runnerup}
					EuropeanMostGoals={post.player.european_topgoalscorer}
					EuropeanMostAssists={post.player.european_mostassists}
					InternationalWins={post.player.international_winner}
					InternationalRunnerUp={post.player.international_runnerup}
					InternationalMostGoals={post.player.international_topgoalscorer}
					InternationalMostAssists={post.player.international_mostassists}
					TeamMostGoals={post.player.team_topgoalscorer}
					TeamMostAssists={post.player.team_mostassists}
					safeMode={props.safeMode}
					/>
				<PlayerStats
					Id={ playerId }
					TotalGoals={post.player.goals}
					Yellow={post.player.yellow_cards}
					Red={post.player.red_cards}/>
				<Box
						bg='blackAlpha.900'
						padding={10}
					>
					<FullStats
						Id={ playerId }/>
					<CupStats
						Id={ playerId }/>
				</Box>
				
	    	</>
	    ) : (
	    	<>
	    		<Helmet>
	            	<title>Websoccer - Loading...</title>
	        	</Helmet>
	        	<Hero 
	        		FirstName="Loading..."
	        		PageType={usePathname}/>
	        	<div className="grid-container">
					<div className="grid-x grid-padding-x align-center text-center">
						<div className="cell small-8 text-center">
							<h1>No Player Found</h1>
						</div>
					</div>
				</div>
	    	</>
	    )
	}
	return(
		<>
			{displayData()}
    </>
	)
}

export default Player
/*

class Player extends Component {
	state = {
		id: this.props.match.params.playerId,
		player: [],
		rendered: false
	}

	componentDidMount(){
		Promise.all([
			Axios.get('player/?id=' + this.state.id)
		])
			.then(([playerResponse]) => {
				this.setState({player: playerResponse.data});
			}, (error) => {
				this.setState({rendered: true});
			})


	}


	render(){
		let redirectPage = null

		if (this.state.rendered === true){
			redirectPage = <Navigate to="/404" />
		}

		let PositionTranslate = this.state.player.position
			if (this.state.player.position === "Torwart"){
				PositionTranslate = "Goalkeeper"
			} else if (this.state.player.position === "Abwehr"){
				PositionTranslate = "Defender"
			} else if (this.state.player.position === "Mittelfeld"){
				PositionTranslate = "Midfielder"
			} else if (this.state.player.position === "Sturm"){
				PositionTranslate = "Forward"
			}

		let PositionMainTranslate = this.state.player.position_main
			if (this.state.player.position_main === "T"){
				PositionMainTranslate = "Goalkeeper"
			} else if (this.state.player.position_main === "LV"){
				PositionMainTranslate = "Left Back"
			} else if (this.state.player.position_main === "IV"){
				PositionMainTranslate = "Centre Back"
			} else if (this.state.player.position_main === "RV"){
				PositionMainTranslate = "Right Back"
			} else if (this.state.player.position_main === "LM"){
				PositionMainTranslate = "Left Midfield"
			} else if (this.state.player.position_main === "DM"){
				PositionMainTranslate = "Defensive Midfield"
			} else if (this.state.player.position_main === "ZM"){
				PositionMainTranslate = "Centre Midfield"
			} else if (this.state.player.position_main === "RM"){
				PositionMainTranslate = "Right Midfield"
			} else if (this.state.player.position_main === "OM"){
				PositionMainTranslate = "Attacking Midfield"
			} else if (this.state.player.position_main === "LS"){
				PositionMainTranslate = "Left Forward"
			} else if (this.state.player.position_main === "MS"){
				PositionMainTranslate = "Striker"
			} else if (this.state.player.position_main === "RS"){
				PositionMainTranslate = "Right Forward"
			}
		let PositionSecondaryTranslate = this.state.player.position_secondary
			if (this.state.player.position_secondary === "T"){
				PositionSecondaryTranslate = "Goalkeeper"
			} else if (this.state.player.position_secondary === "LV"){
				PositionSecondaryTranslate = "Left Back"
			} else if (this.state.player.position_secondary === "IV"){
				PositionSecondaryTranslate = "Centre Back"
			} else if (this.state.player.position_secondary === "RV"){
				PositionSecondaryTranslate = "Right Back"
			} else if (this.state.player.position_secondary === "LM"){
				PositionSecondaryTranslate = "Left Midfield"
			} else if (this.state.player.position_secondary === "DM"){
				PositionSecondaryTranslate = "Defensive Midfield"
			} else if (this.state.player.position_secondary === "ZM"){
				PositionSecondaryTranslate = "Centre Midfield"
			} else if (this.state.player.position_secondary === "RM"){
				PositionSecondaryTranslate = "Right Midfield"
			} else if (this.state.player.position_secondary === "OM"){
				PositionSecondaryTranslate = "Attacking Midfield"
			} else if (this.state.player.position_secondary === "LS"){
				PositionSecondaryTranslate = "Left Forward"
			} else if (this.state.player.position_secondary === "MS"){
				PositionSecondaryTranslate = "Striker"
			} else if (this.state.player.position_secondary === "RS"){
				PositionSecondaryTranslate = "Right Forward"
			}
		return(
			<React.Fragment>
			{redirectPage}
			<Helmet>
                <title>{'Websoccer - ' + this.state.player.firstname + ' ' + this.state.player.surname}</title>
            </Helmet>
			<div>
				<Hero 
					HeroImage1={this.state.player.hero_url}
					HeroImage2={this.state.player.hero_url2}
					HeroImage3={this.state.player.hero_url3}
					FirstName={this.state.player.firstname}
					Surname={this.state.player.surname}
					Psuedoname={this.state.player.psuedoname}
					TotalGames={this.state.player.played}
					TotalGoals={this.state.player.goals}
					TotalAssists={this.state.player.assists}
					TotalYC={this.state.player.yellow_cards}
					TotalRC={this.state.player.red_cards}
					PageType={this.props.match.path}/>
				<Content 
					Id={this.state.id}
					FirstName={this.state.player.firstname}
					Surname={this.state.player.surname}
					Position={PositionTranslate}
					Image1={this.state.player.image_url}
					Image2={this.state.player.image_url2}
					Image3={this.state.player.image_url3}
					Image4={this.state.player.image_url4}
					Image5={this.state.player.image_url5}
					Nationality={this.state.player.nation}
					PositionMain={PositionMainTranslate}
					PositionSecondary={PositionSecondaryTranslate}
					Age={this.state.player.age}
					Wages={this.state.player.wages}
					GoalBonus={this.state.player.goal_bonus}
					Contract={this.state.player.contract}
					Value={this.state.player.value}
					Team={this.state.player.team}
					TeamId={this.state.player.teamId}
					Skill={this.state.player.skill}
					Technique={this.state.player.technique}
					Stamina={this.state.player.stamina}
					Fitness={this.state.player.fitness}
					Morale={this.state.player.morale}
					History={this.state.player.history}
					Injured={this.state.player.injured}
					Suspended={this.state.player.suspension}
					SuspendedCup={this.state.player.suspension_cup}
					TransferListed={this.state.player.transfer_listed}
					/>
				<PlayerStats
					Id={this.state.id}
					Yellow={this.state.player.yellow_cards}
					Red={this.state.player.red_cards}/>
				<FullStats
					Id={this.state.id}/>
				<CupStats
					Id={this.state.id}/>
				
			</div>
			</React.Fragment>
		)
	}
};

*/
