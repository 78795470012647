import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCog } from '@fortawesome/free-solid-svg-icons';

import './SideSlider.scss';

class SlideSlider extends Component {
	state = {
		show: false
	}

	showContainer = () => {
		this.setState({show: !this.state.show})
	}
	render(){
		return(
			<React.Fragment>
				<section className={this.state.show ? "SideSlider open" : "SideSlider"}>
					<div className="Sidebar">
						<button onClick={this.showContainer}><FontAwesomeIcon icon={faUserCog}/></button>
					</div>
						{this.props.children}
				</section>
			</React.Fragment>
		)
	}
}

export default SlideSlider;