import React, { useEffect, useState }from 'react';
import Axios from 'axios';

import {
	Heading,
	Container,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
} from '@chakra-ui/react'

const FullStats = (props) => {
	const [post, setPost] = useState({playerStats:[]});

	useEffect(() => {
			Promise.all([
				Axios.get('player/playerStats/?id=' + props.Id)
			])
			.then(([statsResponse]) => {
				setPost({ playerStats: statsResponse.data.match});
			}, (error) => {
			})
	}, []);

	const groupBy = (array, key) => {
	return array.reduce((result, currentValue) => {
		
		(result[currentValue[key]] = result[currentValue[key]] || []).push(
		currentValue
		);
		return result;
	}, {});	
	};

	const seasonData = groupBy(post.playerStats, 'season');

	const stats = () => {
		return(
		Object.keys(seasonData)
			.sort((a, b) => parseInt(a) < parseInt(b))
			.map((seasonDataKey, i) => {
				const seasonClub = post.playerStats
					.filter(a => a.season == seasonDataKey)
					.map(playerKey => (playerKey.club))
				const seasonLeague = post.playerStats
					.filter(a => a.season == seasonDataKey)
					.map(playerKey => (playerKey.leagueName))
				const seasonName = post.playerStats
					.filter(a => a.season == seasonDataKey)
					.map(playerKey => (playerKey.seasonName))
				const totalMinutesStats = post.playerStats
					.filter(a => a.season == seasonDataKey)
					.reduce((a, currValue) => parseFloat(a) + parseFloat(currValue.minutes_played), 0);
				const totalGoalsStats = post.playerStats
					.filter(a => a.season == seasonDataKey)
					.reduce((a, currValue) => parseFloat(a) + parseFloat(currValue.goals), 0);
				const totalAssistsStats = post.playerStats
					.filter(a => a.season == seasonDataKey)
					.reduce((a, currValue) => parseFloat(a) + parseFloat(currValue.assists), 0);
				const totalYCStats = post.playerStats
					.filter(a => a.season == seasonDataKey)
					.reduce((a, currValue) => parseFloat(a) + parseFloat(currValue.yellow), 0);
				const totalRCStats = post.playerStats
					.filter(a => a.season == seasonDataKey)
					.reduce((a, currValue) => parseFloat(a) + parseFloat(currValue.red), 0);
				const passSuccess = post.playerStats
					.filter(a => a.season == seasonDataKey)
					.reduce((a, currValue) => parseFloat(a) + parseFloat(currValue.passes_successful), 0);
				const passFail = post.playerStats
					.filter(a => a.season == seasonDataKey)
					.reduce((a, currValue) => parseFloat(a) + parseFloat(currValue.passes_failed), 0);
				const totalPasses = (passSuccess + passFail)
				const passPercentile = Number((passSuccess / totalPasses) * 100).toFixed(0)
				const tackleSuccess = post.playerStats
					.filter(a => a.season == seasonDataKey)
					.reduce((a, currValue) => parseFloat(a) + parseFloat(currValue.won_tackles), 0);
				const tackleFail = post.playerStats
					.filter(a => a.season == seasonDataKey)
					.reduce((a, currValue) => parseFloat(a) + parseFloat(currValue.lost_tackles), 0);
				const totaltackles = (tackleSuccess + tackleFail);
				const tacklePercentile = Number((tackleSuccess / totaltackles) * 100).toFixed(0);
				const totalShotsStats = post.playerStats
					.filter(a => a.season == seasonDataKey)
					.reduce((a, currValue) => parseFloat(a) + parseFloat(currValue.shots), 0);
				const shotPercentile = Number((totalGoalsStats / totalShotsStats) * 100).toFixed(0);
				const ratings = post.playerStats
					.filter(a => a.season == seasonDataKey)
					.reduce((a, currValue) => parseFloat(a) + parseFloat(currValue.rating), 0);
				const averageRating = Number(ratings / seasonLeague.length).toFixed(2);

				return(
					<Tr key={i}>
						<Td>{seasonClub[0]}</Td>
						<Td>{seasonLeague[0]}</Td>
						<Td>{seasonName[0]}</Td>
						<Td>{seasonLeague.length}</Td>
						<Td>{totalMinutesStats}</Td>
						<Td>{totalGoalsStats}</Td>
						<Td>{totalAssistsStats}</Td>
						<Td>{totalYCStats}</Td>
						<Td>{totalRCStats}</Td>
						<Td>{passPercentile}%</Td>
						<Td>{tacklePercentile}%</Td>
						<Td>{shotPercentile}%</Td>
						<Td>{averageRating}</Td>
					</Tr>
				)
			}
		))}

	return(
		<>
			<Container
				maxW='container.3xl'
				pt='75px;'
				pb='75px;'
				color="white"
			>
				<Heading
					textTransform="uppercase"
				>
					Full Stats
				</Heading>
				<TableContainer>
						<Table
							size='lg'>
						<Thead>
							<Tr>
								<Th color="white">Team</Th>
								<Th color="white">Competition</Th>
								<Th color="white">Season</Th>
								<Th color="white">Played</Th>
								<Th color="white">Minutes</Th>
								<Th color="white">Goals</Th>
								<Th color="white">Assists</Th>
								<Th color="white">YC</Th>
								<Th color="white">RC</Th>
								<Th color="white">Passes</Th>
								<Th color="white">Tackles</Th>
								<Th color="white">Shots</Th>
								<Th color="white">Avg Rating</Th>
							</Tr>
						</Thead>
						<Tbody>
							{stats()}
						</Tbody>
					</Table>
				</TableContainer>
			</Container>
		</>
	)
}

export default FullStats;

