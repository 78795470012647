import React from 'react';

const input = (props) => {

	let inputElement = null;

	const inputClasses = []

	if(props.invalid && props.shouldValidate && props.touched){
		inputClasses.push('Invalid');
	}

	let validationError = null;
	if (props.invalid && props.touched) {
	    validationError = <p className="errorMessage">{props.errorMessage}</p>;
	}

	switch (props.elementType) {
		case ('input'):
			inputElement = <input 
				className={inputClasses.join(' ')}
				{...props.elementConfig} 
				value={props.value}
				onChange={props.changed}/>;
			break;
		case ('textarea'):
			inputElement = <textarea 
				className={inputClasses.join(' ')}
				{...props.elementConfig} 
				value={props.value}
				onChange={props.changed}/>;
			break;
		default:
			inputElement = <input {...props.elementConfig}
							className={inputClasses.join(' ')}
							value={props.value}
							onChange={props.changed}/>;
	}

	return (
		<React.Fragment>
			<label>{props.label}</label>
			{inputElement}
			{validationError}
		</React.Fragment>
	)
};

export default input;