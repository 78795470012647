import React from 'react';

import { NavLink } from 'react-router-dom';

import { Box, Heading, Flex } from '@chakra-ui/react'

const navigation = () => {
	const navi = [
		{
			id : 1,
			title : 'Home',
			url : '/'
		},
		{
			id : 2,
			title : 'News',
			url : '/News'
		},
		{
			id : 3,
			title : 'Tables',
			url : '/League'
		},
		{
			id : 4,
			title : 'Cups',
			url : '/Cup'
		},
		{
			id : 5,
			title : 'Clubs',
			url : '/Teams'
		},
		{
			id : 6,
			title : 'Transfers Market',
			url : '/Transfer'
		},
		{
			id : 7,
			title : 'National Teams',
			url : '/National-Teams'
		},
		{
			id : 8,
			title : 'Log Out',
			url : '/logout'
		},
	]

	return(
		<>
		<Box
			w="100vw"
			zIndex={100}
			position="fixed"
			top="0"
			bg="blackAlpha.700"
			pt={5}
			pb={5}
		>
			<nav>
				<Flex
					gap='5'
					pl={5}
					pr={5}
				>
					{navi.map(navKey => {
						return(
							<NavLink key={navKey.id} to={navKey.url}>
								<Heading
									fontSize='md'
									color='cyan.200'
									textTransform="uppercase"
								>
									{navKey.title}
								</Heading>
							</NavLink>
						)
					})}
				</Flex>
			</nav>
		</Box>
		</>
	)
}

export default navigation;

