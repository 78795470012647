import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import {
	Heading,
	Card,
	Text,
	Flex,
	CardBody,
} from '@chakra-ui/react'

const Fixtures = (props) => {
	return (
		<>
			<Link to={'/fixture/' + props.id}>
				<Card
					minH="220px"
					bgGradient="linear(to-br, blue.900, blue.700)"
				>
					<CardBody>
					<Text
						textAlign="center"
						color="white"
						mb="10px"
					>
						{moment.unix(props.date).format("dddd D MMMM YYYY")}
					</Text>
					<Heading
						textAlign="center"
						color="white"
						fontSize="3xl"
						textTransform="uppercase"
					>
						{props.hometeam}
					</Heading>
					<Flex
						justifyContent="center"
						alignItems="center"
						gap={5}
					>
						<Heading
							textAlign="center"
							color="white"
							fontSize="6xl"
							textTransform="uppercase"
						>
							{props.homegoals}
						</Heading>
						<Heading
							textAlign="center"
							color="white"
							fontSize="3xl"
							textTransform="uppercase"
						>
							V
						</Heading>
						<Heading
							textAlign="center"
							color="white"
							fontSize="6xl"
							textTransform="uppercase"
						>
							{props.awaygoals}
						</Heading>
					</Flex>
					<Heading
						textAlign="center"
						color="white"
						fontSize="3xl"
						textTransform="uppercase"
					>
						{props.awayteam}
					</Heading>

					</CardBody>
				</Card>
			</Link>
		</>
	)
}

export default Fixtures

				
				