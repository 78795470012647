import React from 'react'

import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableContainer,
    Container,
    Box,
    Heading
  } from '@chakra-ui/react'

const History = (props) => {
    const displayData = () => {
		return props.History ? (
            <>
            <Container
                maxW='container.3xl'
                pt='75px;'
                pb='75px;'
            >
                <Box boxShadow='md' bg='white' w='100%' p={20}>
					<Heading size="2xl" textTransform="uppercase">League History</Heading>
                    <TableContainer>
                        <Table variant='striped'
                            colorScheme='blackAlpha'
                            size='lg'>
                            <Thead>
                            <Tr>
                                <Th>Season</Th>
                                <Th>First</Th>
                                <Th>Second</Th>
                                <Th>Third</Th>
                                <Th>Fourth</Th>
                                <Th>Fifth</Th>
                            </Tr>
                            </Thead>
                            <Tbody>
                                {props.History
                                    .sort((a, b) => a.id < b.id ? 1 : -1)
                                    .map((historyKey, i) =>{
                                    return(
                                        <Tr>
                                            <Td><strong>{ historyKey.name }</strong></Td>
                                            <Td>{ historyKey.first }</Td>
                                            <Td>{ historyKey.second }</Td>
                                            <Td>{ historyKey.third }</Td>
                                            <Td>{ historyKey.fourth }</Td>
                                            <Td>{ historyKey.fifth }</Td>
                                        </Tr>
                                        )
                                    })
                                }
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Box>
            </Container>
            </>

		) : (
			<Container
					maxW='container.3xl'
					pt='75px;'
					pb='75px;'
				>
				<Box boxShadow='md' bg='white' w='100%' p={20}>
					<Heading size="2xl" textTransform="uppercase">No History Found</Heading>
				</Box>
			</Container>
		)
	}

	return(
		<>
			{displayData()}
		</>
	)
}

export default History;