import React from 'react';

import { Link } from 'react-router-dom';

import {
	Heading,
	Box,
	Text,
	Divider,
	HStack,
	Center,
	Flex,
	CardHeader,
	CardBody,
} from '@chakra-ui/react'

const TeamInfo = (props) => {

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'GBP',
            minimumFractionDigits: 0
          });

        const Budget = formatter.format(props.Budget);

	    return(
            <>
                <CardHeader
                    bgGradient="linear(to-br, blue.900, blue.700)"
                >
                    <Heading
                        size='xl'
                        textTransform='uppercase'
                        pb={5}
                        color='teal.200'
                    >
                        Awards
                    </Heading>
                    <Text
                        color='White'
                    >
                        {props.History ? props.History : 'Currently No Achievements'}
                    </Text>
                </CardHeader>
                <Divider />
                <CardBody>
                    <Center>
                        <HStack spacing={6}
                            divider={<Divider borderColor='black' />}
                                >
                            <Box>
                                <Heading
                                size='2xl'
                                textTransform='uppercase'
                                textAlign="center"
                                >
                                    {props.Total_Won}
                                </Heading>
                                <Heading
                                size='md'
                                textTransform='uppercase'
                                textAlign="center"
                                pb={5}
                                >
                                    Wins
                                </Heading>
                            </Box>
                            <Box>
                                <Heading
                                size='2xl'
                                textTransform='uppercase'
                                textAlign="center"
                                >
                                    {props.Total_Draws}
                                </Heading>
                                <Heading
                                size='md'
                                textTransform='uppercase'
                                pb={5}
                                >
                                    Draws
                                </Heading>
                            </Box>
                            <Box>
                                <Heading
                                    size='2xl'
                                    textTransform='uppercase'
                                    textAlign="center"
                                    >
                                    {props.Total_Lost}
                                </Heading>
                                <Heading
                                size='md'
                                textTransform='uppercase'
                                pb={5}
                                >
                                    Losses
                                </Heading>
                            </Box>
                        </HStack>
                    </Center>
                    <Flex alignItems="center" pb={3} pt={3}>
                        <Text fontSize='md'
                            lineHeight={1}
                            textTransform='uppercase'
                            w='65%'
                            fontWeight="700"
                            >
                            Total Goals Scored
                        </Text>
                        <Heading
                            fontSize='3xl'
                            textTransform='uppercase'
                            textAlign="right"
                            w='35%'>
                            {props.Total_Goals}
                        </Heading>
                    </Flex>
                    <Divider/>
                    <Flex alignItems="center" pb={3} pt={3}>
                        <Text fontSize='md'
                            lineHeight={1}
                            textTransform='uppercase'
                            w='65%'
                            fontWeight="700"
                            >
                            Total Goals Conceded
                        </Text>
                        <Heading
                            fontSize='3xl'
                            textTransform='uppercase'
                            textAlign="right"
                            w='35%'>
                            {props.Total_Conceded}
                        </Heading>
                    </Flex>
                    <Divider/>
                    <Flex alignItems="center" pb={3} pt={3}>
                        <Text fontSize='md'
                            lineHeight={1}
                            textTransform='uppercase'
                            w='50%'
                            fontWeight="700"
                            >
                            Current League
                        </Text>
                        <Heading
                            fontSize='md'
                            textTransform='uppercase'
                            textAlign="right"
                            w='50%'>
                            <Link to={'/league/' + props.League_Id}>{props.League}</Link>
                        </Heading>
                    </Flex>
                    <Divider/>
                    <Flex alignItems="center" pb={3} pt={3}>
                        <Text fontSize='md'
                            lineHeight={1}
                            textTransform='uppercase'
                            w='50%'
                            fontWeight="700"
                            >
                            Stadium
                        </Text>
                        <Heading
                            fontSize='md'
                            textTransform='uppercase'
                            textAlign="right"
                            w='50%'>
                            {props.Stadium}
                        </Heading>
                    </Flex>
                    <Divider/>
                    <Flex alignItems="center" pb={3} pt={3}>
                        <Text fontSize='md'
                            lineHeight={1}
                            textTransform='uppercase'
                            w='50%'
                            fontWeight="700"
                            >
                            Budget
                        </Text>
                        <Heading
                            fontSize='md'
                            textTransform='uppercase'
                            textAlign="right"
                            w='50%'>
                            {Budget}
                        </Heading>
                    </Flex>
                </CardBody>
            </>
        )
}

export default TeamInfo