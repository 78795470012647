import React from "react";
import { Formik, Form, Field } from 'formik';

import { transferUpdate } from "../update";

import { 
    FormControl,
    FormLabel,
    Input,
    Button,
    Heading,
    InputGroup,
    InputLeftAddon
 } from '@chakra-ui/react'

const transferList = (props) => {
    

    return(
        <>
             <Formik
            initialValues={
                { 
                    playerId: props.Id,
                    transferStatus: '0'
                }
            }
            onSubmit={async (values, actions) => {
                setTimeout(() => {
                    //alert(JSON.stringify(values, null, 2));
                    transferUpdate(values)
                }, 500)
              }}
            >
                <Form>
                    <FormControl pt={3}>
                        <FormLabel>
                            <Heading fontSize="xl" textTransform='uppercase'>
                                Remove from Transfer List
                            </Heading>
                        </FormLabel>
                        <Button
                            colorScheme='red'
                            isLoading={props.isSubmitting}
                            type='submit'
                            mt={5}
                        >
                            Remove from transfer list
                        </Button>
                    </FormControl>
                </Form>
            </Formik>
        </>
    )
}

export default transferList