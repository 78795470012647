import React from "react";
import { Formik, Form } from 'formik';

import { sackPlayer } from "../update";

import { 
    FormControl,
    Flex,
    IconButton
 } from '@chakra-ui/react'

 import { DeleteIcon } from '@chakra-ui/icons'

const sackedPlayer = (props) => {

    return(
        <>
            <Formik
            initialValues={{ playerId: props.PlayerId }}
            onSubmit={async (values, actions) => {
                setTimeout(() => {
                    sackPlayer(values)
                }, 500)
              }}
            >
                <Form>
                    <FormControl>
                        <Flex justifyContent='center'>
                            <IconButton
                                colorScheme='red'
                                aria-label='Sack Player'
                                icon={<DeleteIcon />}
                                isLoading={props.isSubmitting}
                                type='submit'
							/>
                        </Flex>
                    </FormControl>
                </Form>
            </Formik>
        </>
    )
}

export default sackedPlayer