import React from 'react';

import { AspectRatio, Box, Image } from '@chakra-ui/react'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/effect-fade';

const heroSlider = ( props ) => {

	return (
		<>
            {props.safeMode === 0 ? <AspectRatio 
				maxW='100vw'
				ratio={{ base: 3 / 4, xl: 16 / 9 }}
				backgroundPosition="center"
				backgroundRepeat="no-repeat"
				backgroundSize="cover"
				position="relative"
				_after={{
					content: '""',
					bg: "blackAlpha.500",
					pos: "absolute",
					top: 0,
					right: 0,
					left: 0,
					bottom: 0,
					zIndex: 2
					}}
				>

				<Swiper
					modules={[Autoplay, EffectFade]}
					autoplay={{
						delay: 5000
					}}
					effect="fade"
				>
						{props.content.HeroImage1 ? <SwiperSlide><Image objectFit='cover' w='100vw' objectPosition='center' src={props.content.HeroImage1} alt={props.content.FirstName}/></SwiperSlide> : null}
						{props.content.HeroImage2 ? <SwiperSlide><Image objectFit='cover' w='100vw' objectPosition='center' src={props.content.HeroImage2} alt={props.content.FirstName}/></SwiperSlide> : null}
						{props.content.HeroImage3 ? <SwiperSlide><Image objectFit='cover' w='100vw' objectPosition='center' src={props.content.HeroImage3} alt={props.content.FirstName}/></SwiperSlide> : null}
				</Swiper>
            </AspectRatio> : <Box
				position="relative"
				zIndex={1}
				_after={{
					content: '""',
					bg: "blackAlpha.500",
					pos: "absolute",
					top: 0,
					right: 0,
					left: 0,
					bottom: 0,
					zIndex: 2
				}}>
					<Image 
					src='https://images2.imgbox.com/32/ff/MwCs8AHw_o.jpg'
					w='100vw'
					h='90vh'
					objectFit='cover'
					/>
				</Box>
			}
		</>
	)
}

export default heroSlider;

