import React, { Component} from 'react';
import Axios from 'axios';

import SideSlider from '../../UI/SideSlider/SideSlider';

import { captainUpdate, trainingCampUpdate } from "../update";

class Content extends Component {

	constructor(props) {
        super(props)
        this.state = {
			id:this.props.Id,
			captain : [],
			playerList : [],
			loaded: false,
			captainId: '',
			date: Math.round((new Date()).getTime() / 1000),
			dateEnd: Math.round(((new Date()).getTime() / 1000) + 432000)
		}
		this.captainChange = this.captainChange.bind(this);
		this.captainSelect = this.captainSelect.bind(this);
		this.trainingCamp = this.trainingCamp.bind(this);
	}

	componentDidUpdate(prevProps, prevState){ 
		if (this.state.loaded !== true){
		Axios.get('player/?id=' + this.props.CaptainId)
			.then(playerResponse => {
				this.setState({captain: playerResponse.data, loaded:true});
			}).catch((error) => {
			})
		Axios.get('team/teamlist/?id=' + this.props.Id)
			.then(transferinfoResponse => {
				this.setState({playerList: transferinfoResponse.data.team, loaded:true});
			}).catch((error) => {
			})
		}
		
	}

	captainChange(e) {captainUpdate(this.state)}
	captainSelect(e) {this.setState({captainId : e.target.value})}

	trainingCamp(e) {trainingCampUpdate(this.state)}

	render(){

	const formatter = new Intl.NumberFormat('en-US', {
	  style: 'currency',
	  currency: 'GBP',
	  minimumFractionDigits: 0
	});

	const numberFormat = new Intl.NumberFormat();

	const Budget = formatter.format(this.props.Budget);

	let National_Team = this.props.National_Team

	if (National_Team === "0"){
		return	(
			<>
				<SideSlider>
					<h2>{this.props.Name} Team Options</h2>
					<h3>Team Captain</h3>
					<h4>Current Team Captain - {this.state.captain.firstname} {this.state.captain.surname}</h4>
					<div className="grid-x grid-padding-x">
						<div className="cell auto">
							<select name="captainChoice" id="captainChoice" onChange={this.captainSelect}>
								{this.state.playerList
									.map(player => {
										return <option value={player.playerId}>{player.firstname} {player.surname}</option>
									})
								}
							</select>
						</div>
						<div className="cell shrink">
							<button className="button" onClick={this.captainChange}><h6 class="heading">Select Captain</h6></button>
						</div>
					</div>
					<h3>Stadium Options</h3>
					<h4>Seat Pricing</h4>
					<div className="grid-x grid-padding-x align-middle">
						<div className="cell large-2"></div>
						<div className="cell large-2">
							<h6>Standing</h6>
						</div>
						<div className="cell large-2">
							<h6>Seats</h6>
						</div>
						<div className="cell large-2">
							<h6>Grandstand Standing</h6>
						</div>
						<div className="cell large-2">
							<h6>Grandstand Seats</h6>
						</div>
						<div className="cell large-2">
							<h6>VIP Seats</h6>
						</div>


						<div className="cell large-2">
							<h6>No of Seats</h6>
						</div>
						<div className="cell large-2">
							{numberFormat.format(this.props.StandingNo)}
						</div>
						<div className="cell large-2">
							{numberFormat.format(this.props.SeatsNo)}
						</div>
						<div className="cell large-2">
							{numberFormat.format(this.props.StandingGrandNo)}
						</div>
						<div className="cell large-2">
							{numberFormat.format(this.props.SeatsGrandNo)}
						</div>
						<div className="cell large-2">
							{numberFormat.format(this.props.VIPNo)}
						</div>


						<div className="cell large-2">
							<h6>Price</h6>
						</div>
						<div className="cell large-2">
							{formatter.format(this.props.Standing)}
						</div>
						<div className="cell large-2">
							{formatter.format(this.props.Standing)}
						</div>
						<div className="cell large-2">
							{formatter.format(this.props.Standing)}
						</div>
						<div className="cell large-2">
							{formatter.format(this.props.Standing)}
						</div>
						<div className="cell large-2">
							{formatter.format(this.props.Standing)}
						</div>
					</div>
					<h4 className="pt1">Facilities</h4>
					<div className="grid-x grid-padding-x align-middle">
						<div className="cell large-2"></div>
						<div className="cell large-2">
							<h6>Pitch</h6>
						</div>
						<div className="cell large-2">
							<h6>Video Wall</h6>
						</div>
						<div className="cell large-2">
							<h6>Seat Quality</h6>
						</div>
						<div className="cell large-2">
							<h6>VIP Quality</h6>
						</div>
						<div className="cell large-2"></div>


						<div className="cell large-2">
							<h6>Level</h6>
						</div>
						<div className="cell large-2">
							{this.props.PitchLevel}
						</div>
						<div className="cell large-2">
							{this.props.VideoLevel}
						</div>
						<div className="cell large-2">
							{this.props.SeatsLevel}
						</div>
						<div className="cell large-2">
							{this.props.VIPLevel}
						</div>
						<div className="cell large-2">
						</div>

						<div className="cell large-2">
							<h6>Maintenance Days</h6>
						</div>
						<div className="cell large-2">
							{this.props.PitchMaintenance}
						</div>
						<div className="cell large-2">
							{this.props.VideoMaintenance}
						</div>
						<div className="cell large-2">
							{this.props.SeatsMaintenance}
						</div>
						<div className="cell large-2">
							{this.props.VIPMaintenance}
						</div>
						<div className="cell large-2">
						</div>
					</div>
					<h4 className="pt1">Training Camp</h4>
					<div className="grid-x grid-padding-x">
						<div className="cell shrink">
							<button className="button" onClick={this.trainingCamp}><h6 class="heading">7 Days Training</h6></button>
						</div>
					</div>
				</SideSlider>
			</>
			)
	} else {
		return null
	}
	}
}

export default Content;