import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Navigate} from 'react-router-dom';
import * as actions from '../store/actions/index';

import Input from '../UI/Input/Input';

import './Login.scss';

class Auth extends Component{
	state = {
		authForm:{
			email: {
				elementType: 'input',
				elementConfig: {
					type: 'email',
					placeholder: 'Enter Email',
				},
				value: '',
				validation:{
					required: true,
					email: true
				},
				valid: false,
				touched: false,
				errorMessage: 'Please enter a valid email address'
			},
			password: {
				elementType: 'input',
				elementConfig: {
					type: 'password',
					placeholder: 'Enter Password',
				},
				value: '',
				validation:{
					required: true,
					minLength: 6
				},
				valid: false,
				touched: false,
				errorMessage: 'Please enter a valid password'
			},
		},
		formIsValid:false,
		formMessageSuccess: 'Your message has been successfully sent.',
		formMessageFail: 'Unfortunately your message could not be sent. Please email info@robclarkdesigner.co.uk',
		formSuccess: false,
		formFail:false
	}

	submitForm = (event) =>{
		event.preventDefault();
		this.props.onAuth(this.state.authForm.email.value, this.state.authForm.password.value)
	}

	checkValidation(value, rules){
		let isValid = true

		if (rules.required){
			isValid = value.trim() !== '' && isValid;
		}

		if (rules.minLength){
			isValid = value.length >= rules.minLength && isValid;
		}

		if (rules.maxLength){
			isValid = value.length <= rules.minLength && isValid;
		}

		if (rules.email){
			isValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
		}

		return isValid;
	}

	inputChangedHandler = (event, inputIdentifier) => {
		const updatedauthForm = {
			...this.state.authForm
		};
		const updatedFormElement = {
			...updatedauthForm[inputIdentifier]
		};
		updatedFormElement.value = event.target.value;
		updatedFormElement.valid = this.checkValidation(updatedFormElement.value, updatedFormElement.validation);
		updatedFormElement.touched = true;
		updatedauthForm[inputIdentifier] = updatedFormElement;
		

		let formIsValid = true;
		for (let inputIdentifier in updatedauthForm){
			formIsValid = updatedauthForm[inputIdentifier].valid && formIsValid;
		}

		this.setState({authForm:updatedauthForm, formIsValid: formIsValid});
	}


	

	render(){
		const formElementsArray = [];
		for (let key in this.state.authForm){
			formElementsArray.push({
				id: key,
				config: this.state.authForm[key],
			})
		}

		let authRedirect = null
		if(this.props.isAuthenticated) {
			authRedirect = <Navigate to="/"/>
		}
		return(
			<div className="loginForm">
				{authRedirect}
				<form id="loginForm" onSubmit={this.submitForm}>
					{formElementsArray.map(formElement => (
						<Input 
							key={formElement.id}
							elementType={formElement.config.elementType}
							elementConfig={formElement.config.elementConfig}
							value={formElement.config.value}
							invalid={!formElement.config.valid}
							shouldValidate={formElement.config.validation}
							touched={formElement.config.touched}
							changed={(event) => this.inputChangedHandler(event, formElement.id)}
							errorMessage={formElement.config.errorMessage}/>
					))}
					<div className="text-right">
						<button className="button primary expanded" disabled={!this.state.formIsValid}>Login</button>
					</div>
					<div className="formSuccess">
						{this.state.formSuccess ? this.state.formMessageSuccess : null}
					</div>
					<div className="formFail">
						{this.state.formFail ? this.state.formMessageFail : null}
					</div>
				</form>
			</div>
		)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onAuth: (email, password) => dispatch(actions.auth(email, password))
	};
};

const mapStateToProps = state =>{
  return {
    isAuthenticated: state.auth.token !== null
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth);