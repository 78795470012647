import React, {useState, useEffect} from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

import Hero from '../Components/Hero';

import { Grid, 
	GridItem, 
	Container,
	Card,
	CardBody,
	Heading,
	Avatar,
	Text,
	Progress
} from '@chakra-ui/react'

const Teams = (props) => {
	const [data, setData] = useState({ teams: [], title: 'Teams' });

	useEffect(() => {
			Promise.all([
				Axios.get('teams/')
			])
			.then(([teamsResponse]) => {
				setData({ teams : teamsResponse.data, title: 'Leagues'});
			}, (error) => {
			})
	}, []);

	return(
		<>
			<Helmet>
				<title>Websoccer - Teams</title>
			</Helmet>
			<Hero 
				FirstName={data.title}
				PageType="teams"
				safeMode={props.safeMode} />
			<Container
					maxW='container.3xl'
					pt='75px;'
					pb='75px;'
				>
					<Grid
						templateColumns={{ sm:'repeat(2, 1fr)', lg: 'repeat(5, 1fr)' }}
						gap={6}
					>
						{data.teams
							.filter(teamKey => teamKey.nationalteam === "0")
							.sort(({name: previousName}, {name: currentName}) => previousName > currentName ? 1 : -1)
							.map(teamKey => {
							return(
								<GridItem>
									<Link key={teamKey.id} to={'/teams/' + teamKey.id}>
										<Card
											minH='100px'>
											<CardBody
												display='flex'
												alignItems='center'
												justifyContent='center'
												textAlign="center"
												flexWrap="wrap"
											>
												<Heading
													textTransform='uppercase'
													fontSize='2xl'
												>
													{teamKey.name}
													<Avatar 
														size='2xs'
														src={'https://ws.blue-phoenix.co.uk/img/flags/players/' + teamKey.nation + '.png'}
													 	name={teamKey.nation}
														ml={2} />
												</Heading>
												<Text width="100%" pb={2}>{teamKey.league}</Text>
												<Progress value={teamKey.strength} width="100%"/>
											</CardBody>
										</Card>
									</Link>
								</GridItem>
							)
						})}
					</Grid>
				</Container>
		</>
	)
}

export default Teams;