import React, { useState } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

import Hero from '../Components/Hero';

import { Grid, 
	GridItem, 
	Container,
	Card,
	CardBody,
	Heading,
	Avatar
} from '@chakra-ui/react'

const Leagues = (props) => {
	const [data, setData] = useState({ leagues: [], title: 'Leagues' });

	React.useEffect(() => {
			Promise.all([
				Axios.get('leagues/')
			])
			.then(([leaguesResponse]) => {
				setData({ leagues : leaguesResponse.data, title: 'Leagues'});
			}, (error) => {
			})
	}, []);

	return(
		<>
			<Helmet>
				<title>Websoccer - Leagues</title>
			</Helmet>
			<Hero 
				FirstName={data.title}
				PageType="leagues"
				safeMode={props.safeMode} />
			<Container
					maxW='container.3xl'
					pt='75px;'
					pb='75px;'
				>
					<Grid
						templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)', xl:'repeat(5, 1fr)' }}
						gap={6}
					>
						{data.leagues
							.sort(({name: previousName}, {name: currentName}) => previousName > currentName ? 1 : -1)
							.map(leagueKey => {
							return(
								<GridItem>
									<Link key={leagueKey.id} to={'/league/' + leagueKey.id}>
										<Card
											minH='100px'>
											<CardBody
												display='flex'
												alignItems='center'
												justifyContent='center'
											>
												<Heading
													textTransform='uppercase'
													fontSize='2xl'
												>
													{leagueKey.name}
													<Avatar 
														size='2xs'
														src={'https://ws.blue-phoenix.co.uk/img/flags/players/' + leagueKey.land + '.png'}
													 	name={leagueKey.name}
														ml={2} />
												</Heading>
											</CardBody>
										</Card>
									</Link>
								</GridItem>
							)
						})}
					</Grid>
				</Container>
		</>
	)
}

export default Leagues;

