import React from "react";
import { Formik, Field, Form } from 'formik';

import { bidAccept } from "../update";

import { 
    FormControl,
    FormLabel,
    Select,
    Button,
    Flex,
    Input,
    InputGroup,
    InputLeftAddon
 } from '@chakra-ui/react'

const transferAcceptForm = (props) => {

    
    return(
        <>
            <Formik
            initialValues={{ 
                player_id: props.id,
                id: props.offerId,
                original_team_id: props.originalTeamId,
                offer_team_id: props.teamId,
                offer: props.offer,
                wage: props.wage,
                bonus: props.bonus,
                contract: props.contract,
            }}
            onSubmit={async (values, actions) => {
                setTimeout(() => {
                    bidAccept(values)
                }, 500)
              }}
            >
                <Form>
                    <FormControl>
                        <Button
                                colorScheme='teal'
                                isLoading={props.isSubmitting}
                                type='submit'
                            >
                                Accept Offer
                            </Button>
                    </FormControl>
                </Form>
            </Formik>
        </>
    )
}

export default transferAcceptForm