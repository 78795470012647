import React from 'react';
import Axios from 'axios';
import {Helmet} from "react-helmet";

import Hero from '../../Components/Hero';
import Content from '../../Components/Team/TeamContent';
import TeamList from '../../Components/Team/TeamList';
import NationalSelection from '../../Components/National/Selection';
import Fixture from '../../Components/Team/Fixtures';


import { useLocation, useParams } from 'react-router-dom';

import {
	Container,
	Tabs,
	Tab,
	TabList,
	TabPanels,
	TabPanel
} from '@chakra-ui/react'

const usePathname = () => {
  const location = useLocation();
  return location.pathname;
}

const Team = (props) => {
	const { teamId } = useParams();
	const [post, setPost] = React.useState({team: null, teamList : null, league: null});

	const initialCall = () => {
		Promise.all([
			Axios.get('team/?id=' + teamId),
			Axios.get('national/?id=' + teamId)
		])
		.then(([teamResponse, teamListResponse]) => {
			console.log(teamResponse, teamListResponse)
		    	setPost({ team: teamResponse.data, teamList: teamListResponse.data.team, league : teamResponse.data.league_id});
		}, (error) => {
			Axios.get('team/?id=' + teamId).then((teamResponse) => {
				setPost({ team: teamResponse.data, teamList: null, league : teamResponse.data.league_id});
			})
		})
	}

	React.useEffect(() => {
		initialCall();
  }, []);

	const displayData = () => {
	    return post.team ? (
	      	<>
	      		<Helmet>
	            	<title>Websoccer - { post.team.name }</title>
	        	</Helmet>
	        	<Hero 
	        		FirstName={ post.team.name }
	        		PageType={usePathname}
					safeMode={props.safeMode}/>
				<Container
					maxW='container.3xl'
					pt='75px;'
					pb='75px;'
				>
					<Tabs isFitted mb="2em">
						<TabList>
							<Tab>Squad</Tab>
							<Tab>Calendar</Tab>
							<Tab>Team Selection</Tab>
						</TabList>
						<TabPanels>
							<TabPanel>
								<TeamList
									Id={ teamId }
									National_Team={post.team.national_team}
									Players={ post.teamList }
									safeMode={props.safeMode}
								/>
							</TabPanel>
							<TabPanel>
								<Fixture
								Id={ teamId }/>
							</TabPanel>
							<TabPanel>
							<NationalSelection
								Id={ teamId }
								Name={post.team.name}
								National_Team={post.team.national_team}/>
							</TabPanel>
						</TabPanels>
					</Tabs>
					<Content 
						Id={ teamId }
						League={post.team.league}
						Budget={post.team.budget}
						History={post.team.history}
						Total_Won={post.team.total_won}
						Total_Draws={post.team.total_draws}
						Total_Lost={post.team.total_lost}
						Total_Goals={post.team.total_goals}
						Total_Conceded={post.team.total_conceded}
						League_Id={post.team.league_id}
						National_Team={post.team.national_team}
						Stadium={post.team.stadium}
						Name={post.team.name}
						CaptainId={post.team.captain_id}
						Standing={post.team.preis_stehen}
						Seats={post.team.preis_sitz}
						SeatsGrand={post.team.preis_haupt_sitze}
						StandingGrand={post.team.preis_haupt_stehen}
						VIP={post.team.preis_vip}
						StandingNo={post.team.p_steh}
						SeatsNo={post.team.p_sitz}
						StandingGrandNo={post.team.p_haupt_steh}
						SeatsGrandNo={post.team.p_haupt_sitz}
						VIPNo={post.team.p_vip}
						PitchLevel={post.team.level_pitch}
						VideoLevel={post.team.level_videowall}
						SeatsLevel={post.team.level_seatsquality}
						VIPLevel={post.team.level_vipquality}
						PitchMaintenance={post.team.maintenance_pitch}
						VideoMaintenance={post.team.maintenance_videowall}
						SeatsMaintenance={post.team.maintenance_seatsquality}
						VIPMaintenance={post.team.maintenance_vipquality}
					/>
				</Container>
	        </>
	    ) : (
	    	<>
	      		<Helmet>
	            	<title>Websoccer - Loading...</title>
	        	</Helmet>
	        	<Hero 
	        		FirstName="Loading..."
	        		PageType={usePathname}/>
	        	<div className="grid-container">
					<div className="grid-x grid-padding-x align-center text-center">
						<div className="cell small-8 text-center">
							<h1>No Team Found</h1>
						</div>
					</div>
				</div>
	        </>
	    );
	 }

	return(
		<>
			{displayData()}
    </>
	)
}

export default Team;