import React from "react";
import { Formik, Form } from 'formik';

import { NationalAdd } from "../update";

import { 
    FormControl,
	IconButton 
 } from '@chakra-ui/react'

 import { CheckIcon } from '@chakra-ui/icons'

const nationalAdd = (props) => {

    return(
        <>
            <Formik
            initialValues={{ playerId: props.PlayerId, teamId: props.TeamId }}
            onSubmit={async (values, actions) => {
                setTimeout(() => {
                    NationalAdd(values)
                }, 500)
                
                setTimeout(() => {
                    props.buttonClick()
                }, 1000)
              }}
            >
                <Form>
                    <FormControl>
                        <IconButton
                            colorScheme='green'
                            aria-label='Add Player'
                            icon={<CheckIcon />}
                            type='submit'
                        />
                    </FormControl>
                </Form>
            </Formik>
        </>
    )
}

export default nationalAdd