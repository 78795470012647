import React from 'react';

import GoalLogo from '../Icons/1.png';
import AssistLogo from '../Icons/13.png';
import { 
	Grid,
	GridItem,
	Heading,
	Text,
	Image,
	Box
} from '@chakra-ui/react';


const TeamLineup = (props) => {

	const displayData = () => {
		const positions = ['Torwart', 'Abwehr', 'Mittelfeld', 'Sturm'];
		return props.MatchId ? (
				<>
					<Heading textTransform="uppercase" 
						textAlign="center"
						fontSize="2xl"
						pb={5}>
							Line Ups
					</Heading>
					<Grid templateColumns="repeat(2, 1fr)" gap={6}>
						<GridItem textAlign="right">
							<Heading fontSize="xl" pb={3}>{props.HomeTeam}</Heading>
						</GridItem>
						<GridItem>
							<Heading fontSize="xl" pb={3}>{props.AwayTeam}</Heading>
						</GridItem>
						<GridItem textAlign="right">
							{positions
								.map(positionKey => {
									return(
										<>
										{props.TeamList
											.filter(d => props.HomeTeam === d.team)
											.filter(a => positionKey === a.position)
											.map(playerKey => {
												let goals = []
													if (parseInt(playerKey.goals) > 0){
														goals = [
																...Array(parseInt(playerKey.goals)),
																].map((value: undefined, index: number) => (
																<><Image src={GoalLogo} alt='Goal' boxSize='12px' /></>
																));
													} else {
														goals = null
													}
													let assists = []
													if (parseInt(playerKey.assists) > 0){
														assists = [
																...Array(parseInt(playerKey.assists)),
																].map((value: undefined, index: number) => (
																<><Image src={AssistLogo} alt='Assist' boxSize='12px' /></>
																));
													} else {
														assists = null
													}
													let yellow = []
													if (parseInt(playerKey.yellow) > 0){
														yellow = [
																...Array(parseInt(playerKey.yellow)),
																].map((value: undefined, index: number) => (
																<><Box bg="yellow.100" h="16px" w="10px" borderRadius={2} border="1px solid" borderColor="yellow.500"/></>
																));
													} else {
														yellow = null
													}
													let red = []
													if (parseInt(playerKey.red) > 0){
														red = [
																...Array(parseInt(playerKey.red)),
																].map((value: undefined, index: number) => (
																	<><Box bg="red.100" h="16px" w="10px" borderRadius={2} border="1px solid" borderColor="red.500"/></>
																));
													} else {
														red = null
													}
												return(
													<>
														<Text display="flex" fontSize="sm" justifyContent="flex-end" gap={1} pb={1}>{playerKey.player_name}{goals}{assists}{yellow}{red}</Text>
													</>
												)
										})}
										</>
									)}
								)}
						</GridItem>
						<GridItem>
							{positions
								.map(positionKey => {
									return(
										<>
										{props.TeamList
											.filter(d => props.AwayTeam === d.team)
											.filter(a => positionKey === a.position)
											.map(playerKey => {
												let goals = []
													if (parseInt(playerKey.goals) > 0){
														goals = [
																...Array(parseInt(playerKey.goals)),
																].map((value: undefined, index: number) => (
																<><Image src={GoalLogo} alt='Goal' boxSize='12px' /></>
																));
													} else {
														goals = null
													}
													let assists = []
													if (parseInt(playerKey.assists) > 0){
														assists = [
																...Array(parseInt(playerKey.assists)),
																].map((value: undefined, index: number) => (
																<><Image src={AssistLogo} alt='Assist' boxSize='12px' /></>
																));
													} else {
														assists = null
													}
													let yellow = []
													if (parseInt(playerKey.yellow) > 0){
														yellow = [
																...Array(parseInt(playerKey.yellow)),
																].map((value: undefined, index: number) => (
																<><Box bg="yellow.100" h="16px" w="10px" borderRadius={2} border="1px solid" borderColor="yellow.500"/></>
																));
													} else {
														yellow = null
													}
													let red = []
													if (parseInt(playerKey.red) > 0){
														red = [
																...Array(parseInt(playerKey.red)),
																].map((value: undefined, index: number) => (
																	<><Box bg="red.100" h="16px" w="10px" borderRadius={2} border="1px solid" borderColor="red.500"/></>
																));
													} else {
														red = null
													}
												return(
													<>
														<Text display="flex" fontSize="sm" justifyContent="flex-start" gap={1} pb={1}>{playerKey.player_name}{goals}{assists}{yellow}{red}</Text>
													</>
												)
										})}
										</>
									)}
								)}
						</GridItem>
					</Grid>
				</>
			) : (
				<>
					<div className="large-4 cell">
						<div className="box box__white pb1 pt1 pr1 pl1 border border__top border__primary">
						</div>
					</div>
				</>
			)
	}

	return(
		<>
			{displayData()}
		</>
	)
}

export default TeamLineup