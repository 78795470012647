import React from "react";
import { Formik, Form } from 'formik';

import { contractPlayer } from "../update";

import { 
    FormControl,
    Flex,
    IconButton
 } from '@chakra-ui/react'

 import { RepeatClockIcon } from '@chakra-ui/icons'

const extendContract = (props) => {
    const Wage = parseInt(props.WageOffer);
    const WageIncrease = Wage * 0.05;

    const NewWage = Wage + WageIncrease;

    return(
        <>
            <Formik
            initialValues={{ playerId: props.PlayerId, wageOffer: NewWage }}
            onSubmit={async (values, actions) => {
                setTimeout(() => {
                    contractPlayer(values)
                }, 500)
              }}
            >
                <Form>
                    <FormControl>
                        <Flex justifyContent='center'>
                            <IconButton
                                colorScheme='blue'
                                aria-label='Extend Contract'
                                icon={<RepeatClockIcon />}
                                isLoading={props.isSubmitting}
                                type='submit'
							/>
                        </Flex>
                    </FormControl>
                </Form>
            </Formik>
        </>
    )
}

export default extendContract