import React from 'react';

import { AspectRatio, Box, Image } from '@chakra-ui/react'

const videoBg = (props) => {
	const video = [
		//Backroom Casting
		'//redgifs.com/ifr/fabulousmoralgoose',
		'//redgifs.com/ifr/differentangelicgoitered',

		//Bukkake
		'//redgifs.com/ifr/dapperinferioritaliangreyhound',
		'//redgifs.com/ifr/tensemildram',
		'//redgifs.com/ifr/agileobesegrison',
		'//redgifs.com/ifr/defiantposhferret',
		'//redgifs.com/ifr/spanishmealysongbird',
		'//redgifs.com/ifr/pointedgoodnaturedamethystinepython',
		'//redgifs.com/ifr/accomplishedelegantgoose',
		'//redgifs.com/ifr/stingyimpressionablemarmoset',
		'//redgifs.com/ifr/equalpleasantwattlebird',
		'//redgifs.com/ifr/assuredtwinturaco',
		'//redgifs.com/ifr/maroonsalmonsongbird',

		//Chaturbate
		'//redgifs.com/ifr/authorizedsecretgalapagosdove',
		'//redgifs.com/ifr/bustlingmutedsongbird',
		'//redgifs.com/ifr/orangeredpalatableinvisiblerail',
		'//redgifs.com/ifr/quizzicaloutrageousafricanelephant',
		'//redgifs.com/ifr/wildturbulentxuanhuasaurus',
		'//redgifs.com/ifr/classictornmangabey',
		'//redgifs.com/ifr/intrepidlankyantlion',

		//Celebrity
		'//redgifs.com/ifr/marriedabsolutelangur',
		'//redgifs.com/ifr/thatforkedspidermonkey',
		'//redgifs.com/ifr/twinunhealthyiceblueredtopzebra',
		'//redgifs.com/ifr/ivoryfelinetoadfish',
		'//redgifs.com/ifr/extrovertedfirsttigershark',
		'//redgifs.com/ifr/dualausterenandoo',
		'//redgifs.com/ifr/queasyshowyhornbill',
		'//redgifs.com/ifr/adeptslateblueamericanavocet',
		'//redgifs.com/ifr/bountifulillegalharlequinbug',
		'//redgifs.com/ifr/ecstaticpungentwhelp',
		'//redgifs.com/ifr/impoliteickyinvisiblerail',
		'//redgifs.com/ifr/measlyinfinitevirginiaopossum',
		'//redgifs.com/ifr/flickeringjuniordore',
		'//redgifs.com/ifr/webbedmiserlystud',

		//Czech Streets
		'//redgifs.com/ifr/firebrickthickchinesecrocodilelizard',
		'//redgifs.com/ifr/roundoffbeatoctopus',
		'//redgifs.com/ifr/flakygraytuatara',
		'//redgifs.com/ifr/lightcoralslowperch',
		'//redgifs.com/ifr/readyimprobableargentineruddyduck',
		'//redgifs.com/ifr/limemetallicbarasingha',
		'//redgifs.com/ifr/spectacularsurefootedfennecfox',
		'//redgifs.com/ifr/veneratedhighgrizzlybear',
		'//redgifs.com/ifr/squarehappycrustacean',
		'//redgifs.com/ifr/fortunateforthrightdrongo',
		'//redgifs.com/ifr/barevastindigowingedparrot',

		//DorcelClub
		'//redgifs.com/ifr/profusethistleiberianmole',
		'//redgifs.com/ifr/adepttalkativemockingbird',
		'//redgifs.com/ifr/excellentvaluabletayra',
		'//redgifs.com/ifr/luxuriousaccuratehedgehog',
		'//redgifs.com/ifr/unrulydelicioustarsier',
		'//redgifs.com/ifr/badangelicasianconstablebutterfly',
		'//redgifs.com/ifr/afraidspringgreentiti',
		'//redgifs.com/ifr/crookedsociablehawk',
		'//redgifs.com/ifr/mediumslateblueexcellentminibeast',
		'//redgifs.com/ifr/utilizedthankfulpussycat',
		'//redgifs.com/ifr/illiteratenegativeamurstarfish',
		'//redgifs.com/ifr/trivialalienatedsnail',
		'//redgifs.com/ifr/peskyunequaledapisdorsatalaboriosa',
		'//redgifs.com/ifr/itchymedicaltomtit',
		'//redgifs.com/ifr/identicalsnowgermanpinscher',
		'//redgifs.com/ifr/novelcomplicatedbaldeagle',
		'//redgifs.com/ifr/clutteredusedbighorn',
		'//redgifs.com/ifr/negativetwinchinchilla',
		'//redgifs.com/ifr/aridfrankbighorn',
		'//redgifs.com/ifr/wellgroomedmaroonarmednylonshrimp',
		'//redgifs.com/ifr/briefmonthlyfattaileddunnart',
		'//redgifs.com/ifr/differentchillyleveret',
		'//redgifs.com/ifr/tintedgreencrustacean',
		'//redgifs.com/ifr/boldbiodegradablewaterbuffalo',
		'//redgifs.com/ifr/flippantlinedherring',
		'//redgifs.com/ifr/substantialaggressivequail',
		'//redgifs.com/ifr/tepidnegativelovebird',
		'//redgifs.com/ifr/formalidioticilsamochadegu',
		'//redgifs.com/ifr/huskykosherbeardeddragon',
		'//redgifs.com/ifr/putridthornyasiandamselfly',
		'//redgifs.com/ifr/gainsborogivingbird',
		'//redgifs.com/ifr/wetturbulentyellowjacket',
		'//redgifs.com/ifr/shadydarkcyansongbird',

		//Eufrat
		'//redgifs.com/ifr/voluminousbuzzingdairycow',

		//Family Therapy
		'//redgifs.com/ifr/boilingripewaxwing',
		'//redgifs.com/ifr/heftynimblebrocketdeer',

		//Games
		'//redgifs.com/ifr/zestybarrenpeafowl',
		'//redgifs.com/ifr/moistcarelessimperatorangel',

		//Lilus Handjobs
		'//redgifs.com/ifr/flusteredrespectfulyellowbellylizard',
		'//redgifs.com/ifr/viciousflawedalaskanhusky',
		'//redgifs.com/ifr/likabledesertedindigowingedparrot',
		'//redgifs.com/ifr/attentiveexaltedzebradove',

		//MFC
		'//redgifs.com/ifr/aggressiveseveralgnatcatcher',
		'//redgifs.com/ifr/serenebitterscaup',
		'//redgifs.com/ifr/wordypoisedwildebeest',
		'//redgifs.com/ifr/monstrousdishonestnightheron',
		'//redgifs.com/ifr/samemildiguana',
		'//redgifs.com/ifr/suspiciousredintermediateegret',
		'//redgifs.com/ifr/swiftutilizedflyingfox',

		//Netvideogirls
		'//redgifs.com/ifr/lumberingdarkorchidbrownbutterfly',
		'//redgifs.com/ifr/bewitcheddampbluefintuna',
		'//redgifs.com/ifr/jauntywastefulguernseycow',
		'//redgifs.com/ifr/goldenillchevrotain',
		'//redgifs.com/ifr/sophisticatedacrobaticyellowthroat',
		'//redgifs.com/ifr/lumpylightslatebluescoter',
		'//redgifs.com/ifr/jauntyyellowishflea',
		'//redgifs.com/ifr/violettanpufferfish',
		'//redgifs.com/ifr/feminineopulentflounder',
		'//redgifs.com/ifr/sizzlingsuperiorasianwaterbuffalo',
		'//redgifs.com/ifr/felinecircularazurevasesponge',
		'//redgifs.com/ifr/vastspotteddutchshepherddog',
		'//redgifs.com/ifr/excitedmarriedyellowlegs',
		'//redgifs.com/ifr/talkativesorearcherfish',
		'//redgifs.com/ifr/overduelooseapatosaur',
		'//redgifs.com/ifr/zealousvapiderin',
		'//redgifs.com/ifr/giganticinsistentoctopus',
		'//redgifs.com/ifr/bleakshrillichthyostega',
		'//redgifs.com/ifr/knowingstingyamericanquarterhorse',
		'//redgifs.com/ifr/majorfakesow',
		'//redgifs.com/ifr/babyishangelicpufferfish',
		'//redgifs.com/ifr/wiryspecificwhitepelican',
		'//redgifs.com/ifr/fragrantazureswellfish',
		'//redgifs.com/ifr/elatedclassicbunny',
		'//redgifs.com/ifr/darkslategrayclumsybellfrog',
		'//redgifs.com/ifr/lostvastindianspinyloach',
		'//redgifs.com/ifr/unknownvioletarrowana',
		'//redgifs.com/ifr/wellgroomedspiriteddogfish',
		'//redgifs.com/ifr/fabuloussombertilefish',
		'//redgifs.com/ifr/noteworthyfavoritepig',
		'//redgifs.com/ifr/blanchedalmondspiritedmarabou',
		'//redgifs.com/ifr/wellmadevacantpolliwog',
		'//redgifs.com/ifr/infantilesingleazurevase',
		'//redgifs.com/ifr/clearcutintentivorybackedwoodswallow',
		'//redgifs.com/ifr/vigilantsorrowfulshoveler',
		'//redgifs.com/ifr/enchantingspiffysow',
		'//redgifs.com/ifr/papayawhipfractalmeerkat',
		'//redgifs.com/ifr/outlandishjoyoushoopoe',
		'//redgifs.com/ifr/unsteadymotionlessermine',
		'//redgifs.com/ifr/perfumedroyalbluemolly',
		'//redgifs.com/ifr/easygoingsnoopyauklet',

		//Public Invasion
		'//redgifs.com/ifr/snoopystarkmalamute',
		'//redgifs.com/ifr/weelargemongrel',

		//Public Pickup
		'//redgifs.com/ifr/blondsardonicurva',

		//Shoplyfter
		'//redgifs.com/ifr/capitalsimplisticguineapig',
		'//redgifs.com/ifr/adolescenteverycuttlefish',

		//Violet Moreau
		'//redgifs.com/ifr/darkorangecrimsoneel',

		//Xev Bellringer
		'//redgifs.com/ifr/sarcasticbeigetortoise',
		'//redgifs.com/ifr/delayedenergeticantarcticfurseal',
		'//redgifs.com/ifr/grizzledintrepiddunnart',
		'//redgifs.com/ifr/tiredenchantedlocust',

		//Random
		'//redgifs.com/ifr/illustriousazurelamb',
		'//redgifs.com/ifr/creamywarmhearteddoctorfish',
		'//redgifs.com/ifr/whitesmokecrimsonxeme',
		'//redgifs.com/ifr/lightcoralhotnarwhal',
		'//redgifs.com/ifr/frizzydimgreygibbon',
		'//redgifs.com/ifr/rotatingexperiencedbluebottlejellyfish',
		'//redgifs.com/ifr/rowdyextraneousannelida',
		'//redgifs.com/ifr/entirebabyishtapir',
		'//redgifs.com/ifr/parchedmajorbaleenwhale',
		'//redgifs.com/ifr/pleasantqueasyamericancurl',
		'//redgifs.com/ifr/grayneedydonkey',
		'//redgifs.com/ifr/jadedburlywoodleonberger',
		'//redgifs.com/ifr/healthytealsockeyesalmon',
		'//redgifs.com/ifr/trimdimgreycrow',
	]
	const poster = [
		//Alexandra Daddario
		"https://images2.imgbox.com/05/eb/6swyDEMr_o.jpg",
		//Amy Adams
		"https://images2.imgbox.com/8e/69/X2selYBu_o.jpg",
		//Anna Paquin
		"https://images2.imgbox.com/fe/b0/C0c6YqsL_o.jpg",
		//Anne Hathaway
		"https://images2.imgbox.com/86/13/kxAG0QuA_o.jpg",
		//Cara Delevingne
		"https://images2.imgbox.com/94/96/BPFazVWQ_o.jpg",
		"https://images2.imgbox.com/e1/e2/4HepDvjB_o.jpg",
		"https://images2.imgbox.com/54/8c/NEbvaVhK_o.jpg",
		//Carice van Houten
		"https://images2.imgbox.com/bc/e5/hzlDsCeD_o.jpg",
		//Christina Agiulera
		"https://images2.imgbox.com/ed/61/LAh3QuHS_o.jpg",
		//Christina Ricci
		"https://images2.imgbox.com/15/b6/RTHkgD9F_o.png",
		"https://images2.imgbox.com/e8/c9/nPgigRd3_o.png",
		"https://images2.imgbox.com/f8/98/akbjQrFe_o.png",
		//Devon Aoki
		"https://images2.imgbox.com/90/1b/FoggqIb4_o.jpg",
		//Emilia Clarke
		"https://images2.imgbox.com/14/bc/NcHqMpcx_o.jpg",
		//Jessica Beal
		"https://images2.imgbox.com/52/27/juCbbXzG_o.jpg",
		"https://images2.imgbox.com/e3/59/lyuxAcDW_o.jpg",
		//Juno Temple
		"https://images2.imgbox.com/c2/d2/B7pAuuUc_o.jpg",
		//Kate Mara
		"https://images2.imgbox.com/d6/09/OBhCCtKa_o.jpg",
		//Kirsten Dunst
		"https://images2.imgbox.com/c3/e4/l2HZnN80_o.jpg",
		"https://images2.imgbox.com/0d/ea/1994MMBY_o.jpg",
		"https://images2.imgbox.com/25/ee/HomKJcaF_o.jpg",
		//Jewel Staite
		"https://images2.imgbox.com/c9/d4/mnAFgcN1_o.jpg",
		//Lea Seydoux
		"https://images2.imgbox.com/f4/a4/HcWFzPlF_o.jpg",
		//Lily Allen
		"https://images2.imgbox.com/67/37/1UFNpsfN_o.jpg",
		//Lucy Liu
		"https://images2.imgbox.com/29/ef/3fLW3zjc_o.jpg",
		"https://images2.imgbox.com/31/c0/AY6WenXn_o.jpg",
		//Marion Cotillard
		"https://images2.imgbox.com/9e/e4/QmvyUuaG_o.png",
		"https://images2.imgbox.com/cf/5b/YBqyDdcJ_o.png",
		//Natalie Dormer
		"https://images2.imgbox.com/58/79/Hh5TXqNd_o.jpg",
		"https://images2.imgbox.com/f4/1b/Ayp5htht_o.jpg",
		"https://images2.imgbox.com/76/c8/3BlGlo9E_o.jpg",
		//Olivia Wilde
		"https://images2.imgbox.com/68/b3/M2DBGNI3_o.jpg",
		"https://images2.imgbox.com/aa/ac/dopRwtHL_o.jpg",
		"https://images2.imgbox.com/fa/e8/SOLQhHfH_o.jpg",
		"https://images2.imgbox.com/c1/38/6JbjT3uA_o.jpg",
		"https://images2.imgbox.com/29/55/iapIS0T1_o.jpg",
		//Rachel Weisz
		"https://images2.imgbox.com/6d/f3/NN2jEIPX_o.jpg",
		//Rooney Mara
		"https://images2.imgbox.com/4c/ec/XgBwc4tu_o.png",
		//Rosabel Laurenti Sellers
		"https://images2.imgbox.com/51/f3/6uaziFbi_o.jpg",
		//Tuppence Middleton
		"https://images2.imgbox.com/82/d7/l1TYhe0b_o.jpg",
		//Zsuzsanna Ripli
		"https://images2.imgbox.com/b3/a7/ZEoFv8Pp_o.jpg",
		"https://images2.imgbox.com/66/f1/g1VaxIsb_o.jpg",


		//B2B Massage Minx
		'https://images2.imgbox.com/9e/77/ITi7kig1_o.jpg',
		'https://images2.imgbox.com/d3/3a/edDyfdby_o.jpg',
		'https://images2.imgbox.com/8a/b2/bA6blC6n_o.jpg',
		'https://images2.imgbox.com/5a/a2/dVz0fMs8_o.jpg',
		'https://images2.imgbox.com/10/54/6uI5qeuC_o.jpg',
		//FoxyStephanie1111
		'https://images2.imgbox.com/56/ea/miGG4rl3_o.jpeg',
		//GingerGreat
		'https://images2.imgbox.com/47/88/Bwwqpzth_o.jpg',
		//Majo20
		'https://images2.imgbox.com/6b/bc/lRUpcMPd_o.jpg',
		//Nikkiyang
		'https://images2.imgbox.com/c2/1f/rrtqoMBf_o.jpg',
		'https://images2.imgbox.com/7a/a4/Z3Knwnl9_o.jpg',
		//Talisma
		'https://images2.imgbox.com/94/a1/8HDMG4Tj_o.jpg',
		'https://images2.imgbox.com/ae/c2/y4rv0sGX_o.jpg',

		//AlessiaCandy
		'https://images2.imgbox.com/e4/e9/0Uyan1uV_o.jpg',
		'https://images2.imgbox.com/80/95/ltRZFiyG_o.jpg',
		//AliceInLove
		'https://images2.imgbox.com/e0/76/YEuORZE9_o.jpg',
		'https://images2.imgbox.com/de/e3/ZbCpPq8J_o.jpg',
		'https://images2.imgbox.com/ba/49/weA81boD_o.jpg',
		//HelenHeights
		'https://images2.imgbox.com/d8/c3/sU6PT2l4_o.jpg',
		//CaraUK
		'https://images2.imgbox.com/8f/2b/fpCJgSe8_o.jpg',
		'https://images2.imgbox.com/1e/07/5aReu1Bc_o.jpg',
		'https://images2.imgbox.com/da/6f/0yNd0M8p_o.jpg',
		//ChrisBrunette
		'https://images2.imgbox.com/70/a7/zu0nE6ol_o.jpg',
		'https://images2.imgbox.com/fa/23/cLPxHUwl_o.jpg',
		'https://images2.imgbox.com/7c/90/DHZ8OG5I_o.jpg',
		//ItalianBoobs
		'https://images2.imgbox.com/49/30/nDxYrby7_o.jpg',
		'https://images2.imgbox.com/32/e5/LiscEkMK_o.jpg',
		'https://images2.imgbox.com/92/2e/AbtrumKh_o.jpg',
		'https://images2.imgbox.com/d0/eb/haKCEA65_o.jpg',
		'https://images2.imgbox.com/31/31/JzhSXsfJ_o.jpg',
		'https://images2.imgbox.com/e2/aa/XANK8XYv_o.jpg',
		'https://images2.imgbox.com/3c/b4/Yuup0n2S_o.jpg',
		'https://images2.imgbox.com/f5/93/PCJ6ttmy_o.jpg',
		'https://images2.imgbox.com/e2/9a/NlSrJ7iL_o.jpg',
		'https://images2.imgbox.com/3d/e4/HebzfFsO_o.jpg',
		//JennyMFC
		'https://images2.imgbox.com/ca/d3/vSCJsUc4_o.jpg',
		'https://images2.imgbox.com/44/43/3HxScf39_o.jpg',
		'https://images2.imgbox.com/96/07/ibBzG56p_o.jpg',
		//KookyUK
		'https://images2.imgbox.com/73/60/JF41u5iO_o.jpg',
		'https://images2.imgbox.com/af/c8/1mBokkS3_o.jpg',
		'https://images2.imgbox.com/51/1f/d4agDHGa_o.jpg',
		'https://images2.imgbox.com/f1/a6/Fi5PwoNO_o.jpg',
		'https://images2.imgbox.com/41/ca/tdKWSDzE_o.jpg',
		'https://images2.imgbox.com/b7/77/wN97t8aT_o.jpg',
		//AvaIsabelle
		'https://images2.imgbox.com/47/40/opeItaKw_o.jpg',
		//RitaKim
		'https://images2.imgbox.com/76/24/Kd8RmOhH_o.jpg',
		'https://images2.imgbox.com/b9/9a/GniT72VV_o.jpg',
		'https://images2.imgbox.com/6c/40/b8rJros1_o.jpg',
		'https://images2.imgbox.com/35/56/MIduzDbs_o.jpg',
		'https://images2.imgbox.com/17/a4/EKAyFG8c_o.jpg',
		'https://images2.imgbox.com/a6/da/7Q52pOoh_o.jpg',
	]

	let number = (Math.floor(Math.random() * video.length));
	let selectedVideo = video[number];
	let numberPoster = (Math.floor(Math.random() * poster.length));
	let selectedPoster = poster[numberPoster];

	return (
		<>
			{props.safeMode === 0 ? <AspectRatio 
				maxW='100vw'
				ratio={{ base: 3 / 4, xl: 16 / 9 }}
				backgroundImage= {selectedPoster}
				backgroundPosition="center"
				backgroundRepeat="no-repeat"
				backgroundSize="cover"
				position="relative"
				overflow='hidden'
				_after={{
					content: '""',
					bg: "blackAlpha.500",
					pos: "absolute",
					top: 0,
					right: 0,
					left: 0,
					bottom: 0,
					zIndex: 2
					}}
				>
				<iframe src={selectedVideo} style={{top: '50%', left:'50%', right:'auto', bottom: 'auto', maxHeight:'100%', width:'250%', transform:'translate(-50%, -50%)'}} title="Hero Backup"/>
			</AspectRatio>
			: <Box
				position="relative"
				zIndex={1}
				h='90svh'
				_after={{
					content: '""',
					bg: "blackAlpha.500",
					pos: "absolute",
					top: 0,
					right: 0,
					left: 0,
					bottom: 0,
					zIndex: 2
				}}>
					<Image 
					src="https://images2.imgbox.com/32/ff/MwCs8AHw_o.jpg"
					w='100vw'
					h='90svh'
					objectFit='cover'
					/>
				</Box>
			}
		</>
	)
}

export default videoBg;

/*<Iframe url={selectedVideo}
					height="100%"
					width="100%"
					position="absolute"
					styles={{top:0, left:0}}
				/>*/