import React from "react";
import { Formik, Form } from 'formik';

import { NationalRemove } from "../update";

import { 
    FormControl,
	IconButton 
 } from '@chakra-ui/react'

 import { SmallCloseIcon } from '@chakra-ui/icons'

const nationalRemove = (props) => {

    return(
        <>
            <Formik
            initialValues={{ playerId: props.PlayerId }}
            onSubmit={async (values, actions) => {
                setTimeout(() => {
                    NationalRemove(values)
                }, 500)
                setTimeout(() => {
                    props.buttonClick()
                }, 1000)
              }}
            >
                <Form>
                    <FormControl>
                        <IconButton
                            colorScheme='red'
                            aria-label='Remove Player'
                            icon={<SmallCloseIcon />}
                            type='submit'
                        />
                    </FormControl>
                </Form>
            </Formik>
        </>
    )
}

export default nationalRemove