import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

import Hero from '../../Components/Hero';

import { Grid, 
	GridItem, 
	Container,
	Card,
	CardBody,
	Heading
} from '@chakra-ui/react'

const Cups = (props) => {
	const [data, setData] = useState({ cups: [], title: 'Leagues' });

	useEffect(() => {
			Promise.all([
				Axios.get('cups/')
			])
			.then(([cupResponse]) => {
				setData({ cups: cupResponse.data, title: 'Cups'});
			}, (error) => {
			})
	}, []);

	return(
		<>
		<Helmet>
				<title>Websoccer - Cups</title>
			</Helmet>
			<Hero 
				FirstName={data.title}
				PageType="cup"
				safeMode={props.safeMode} />
			<Container
					maxW='container.3xl'
					pt='75px;'
					pb='75px;'
				>
					<Grid
						templateColumns='repeat(5, 1fr)'
						gap={6}
					>
						<GridItem colSpan={5}>
							<Heading
								textTransform='uppercase'
								fontSize='7xl'
							>
								Current Cups
							</Heading>
						</GridItem>
						{data.cups
							.filter(cupKey => cupKey.archived === "0")
							.sort(({name: previousName}, {name: currentName}) => previousName > currentName ? 1 : -1)
							.map(cupKey => {
							return(
								<>
									<GridItem>
										<Link key={cupKey.id} to={'/cup/' + cupKey.id}>
											<Card
												minH='100px'>
												<CardBody
													display='flex'
													alignItems='center'
													justifyContent='center'
												>
													<Heading
														textTransform='uppercase'
														fontSize='2xl'
													>
														{cupKey.name}
													</Heading>
												</CardBody>
											</Card>
										</Link>
									</GridItem>
								</>
							)
						})}
						<GridItem colSpan={5}>
							<Heading
								textTransform='uppercase'
								fontSize='7xl'
							>
								Archived Cups
							</Heading>
						</GridItem>
						{data.cups
							.filter(cupKey => cupKey.archived === "1")
							.sort(({name: previousName}, {name: currentName}) => previousName > currentName ? 1 : -1)
							.map(cupKey => {
							return(
								<>
									<GridItem>
										<Link key={cupKey.id} to={'/cup/' + cupKey.id}>
											<Card
												minH='100px'>
												<CardBody
													display='flex'
													alignItems='center'
													justifyContent='center'
												>
													<Heading
														textTransform='uppercase'
														fontSize='2xl'
													>
														{cupKey.name}
													</Heading>
												</CardBody>
											</Card>
										</Link>
									</GridItem>
								</>
							)
						})}
					</Grid>
			</Container>
		</>
	)
}

export default Cups;