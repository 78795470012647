import React from 'react';
import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/free-mode';

import { AspectRatio } from '@chakra-ui/react'

const PlayerSlider = (props) => {
	const [open, setOpen] = React.useState(false);
	const fullscreenRef = React.useRef(null);
	  
	return(
		<>
		<AspectRatio 
			ratio={3 / 4}
		>
			<Swiper
				modules={[Autoplay, FreeMode]}
				autoplay={{
					delay: 5000
				}}
				freeMode={{
					enabled:true,
					sticky: true,
				}}
			>
					{props.Image1 ? <SwiperSlide><img src={props.Image1} alt="Carousel" onClick={() => setOpen(true)}/></SwiperSlide> : <SwiperSlide><img src="https://ws.blue-phoenix.co.uk/img/No-Image.jpg" alt="No Carousel"/></SwiperSlide> }
					{props.Image2 ? <SwiperSlide><img src={props.Image2} alt="Carousel" onClick={() => setOpen(true)}/></SwiperSlide> : null }
					{props.Image3 ? <SwiperSlide><img src={props.Image3} alt="Carousel" onClick={() => setOpen(true)}/></SwiperSlide> : null }
					{props.Image4 ? <SwiperSlide><img src={props.Image4} alt="Carousel" onClick={() => setOpen(true)}/></SwiperSlide> : null }
					{props.Image5 ? <SwiperSlide><img src={props.Image5} alt="Carousel" onClick={() => setOpen(true)}/></SwiperSlide> : null }
			</Swiper>
		</AspectRatio>
		<Lightbox
			open={open}
			close={() => setOpen(false)}
			plugins={[Fullscreen, Zoom]}
			fullscreen={{ ref: fullscreenRef }}
			on={{
				click: () => fullscreenRef.current?.enter()
			}}
			slides={[
				{ src: props.Image1 },
				{ src: props.Image2 },
				{ src: props.Image3 },
				{ src: props.Image4 },
				{ src: props.Image5 },
			]}
		/>
		</>
	)
}

export default PlayerSlider;

