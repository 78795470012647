import React, {Component} from 'react';
import Axios from 'axios';

import Fixtures from './Fixtures';
import Groups from './Groups';

import { 
	Heading,
	Container,
	Grid,
	GridItem
} from '@chakra-ui/react'

class Round extends Component{
	state = {
		id : this.props.id,
		rounds: [],
		fixtures: []
	}

	componentWillMount(){
		Promise.all([
			Axios.get('cup/rounds/?id=' + this.state.id),
			Axios.get('cup/fixtures/?id=' + this.state.id),
		])
			.then(([cupResponse, fixtureResponse]) => {
				this.setState({rounds: cupResponse.data.cup_round, fixtures: fixtureResponse.data.fixture});
			}).catch((error) => {
				console.log(error);
			})
	}
	render(){
		return(
			<>
				<Container
					maxW='container.3xl'
					pt='75px;'
					pb='75px;'
				>
					{this.state.rounds
						.map(roundKey => {
							return(
								<>
									<Heading textTransform="uppercase" pb={5} pt={10}>{roundKey.name}</Heading>
									<Grid 
										templateColumns='repeat(5, 1fr)'
										gap={2}>
										{this.state.fixtures
											.filter(fixturesKey => fixturesKey.cup_round === roundKey.name && fixturesKey.cup_group === null)
											.map(fixturesKey => {
												return(
													<GridItem>
														<Fixtures 
															id = {fixturesKey.id}
															date = {fixturesKey.date}
															hometeam = {fixturesKey.home_team}
															homegoals = {fixturesKey.home_goals}
															awaygoals = {fixturesKey.away_goals}
															awayteam = {fixturesKey.away_team}
														/>
													</GridItem>
												)
										})}
									</Grid>
									
									<Groups
										id = {roundKey.id}
									/>
								</>
							)
					})}
				</Container>
		</>
		)
	}
}

export default Round 