import React, {Component} from 'react';
import Axios from 'axios';
import moment from 'moment';

import {
	Heading,
	Card,
	CardHeader,
	Box,
	CardBody,
} from '@chakra-ui/react'

import Fixture from '../Fixtures/Fixture'

class fixture extends Component{
	state = {
		id : this.props.Id,
		fixtures: []
	}
	componentWillMount(){
		Axios.get('fixtures/?id=' + this.state.id)
			.then(teamResponse => {
				this.setState({fixtures: teamResponse.data.fixture});
			}).catch((error) => {
				console.log(error);
			})
	}

	render(){
		let date = Math.floor(Date.now() / 1000)
		return(
			<>
				<Box>
					<Heading
						textTransform='uppercase'
					>
						Fixtures
					</Heading>
					{this.state.fixtures
									.sort((a, b) => a.date - b.date)
									.filter(d => date > d.date)
									.reverse()
									.slice(0, 1)
									.map((fixtureKey, key) => {
									return (
						<Card
							minH="270px"
							mb="10px"
							bgGradient="linear(to-br, blue.900, blue.700)"
						>
							<CardHeader
								pb="0"
								mb="0"
							>
								<Heading
									size='xl'
									textTransform='uppercase'
									color='teal.200'
								>
									Last Result
								</Heading>
							</CardHeader>
							<CardBody>
								<Fixture
										id={fixtureKey.id}
										key={fixtureKey.id}
										hometeam={fixtureKey.hometeam}
										homegoals={fixtureKey.homegoals}
										awayteam={fixtureKey.awayteam}
										awaygoals={fixtureKey.awaygoals}
										date={fixtureKey.date}/>
							</CardBody>
						</Card>
						)
					})}
					{this.state.fixtures
						.sort((a, b) => {
							return moment.unix(a.date).format("DD/MM/YYYY") - 
								moment.unix(b.date).format("DD/MM/YYYY")
							})
						.filter(d => date < d.date)
						.slice(0, 1)
						.map((fixtureKey, key) => {
						return (
						<Card
							minH="270px"
							bgGradient="linear(to-br, blue.900, blue.700)"
						>
							<CardHeader
								pb="0"
								mb="0"
							>
								<Heading
									size='xl'
									textTransform='uppercase'
									color='teal.200'
								>
									Next Fixture
								</Heading>
							</CardHeader>
							<CardBody>
								<Fixture
									id={fixtureKey.id}
									key={fixtureKey.id}
									hometeam={fixtureKey.hometeam}
									homegoals={fixtureKey.homegoals}
									awayteam={fixtureKey.awayteam}
									awaygoals={fixtureKey.awaygoals}
									date={fixtureKey.date}/>
							</CardBody>
						</Card>
						)
					})}
				</Box>
			</>
		)
	}
}

export default fixture