import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import {thunk} from 'redux-thunk';

import axios from 'axios';
import {Provider } from 'react-redux';

import authReducer from './store/reducers/auth';

const rootReducer = combineReducers({
	auth: authReducer
})

axios.defaults.baseURL = 'https://ws.blue-phoenix.co.uk/webservices/api/';

axios.interceptors.request.use(request =>{
	return request;
}, error =>{
	return Promise.reject(error);
});

axios.interceptors.response.use(response =>{
	return response;
}, error =>{
	return Promise.reject(error);
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
