import React from 'react';
import Axios from 'axios';
import TeamCaptain from '../_FormFields/teamCaptian';
import StadiumUpgrade from '../_FormFields/stadiumUpgrade';
import StadiumMaintenance from '../_FormFields/stadiumMaintenance';
import TrainingCamp from '../_FormFields/trainingCamp';

import { 
	Container,
    Box,
    Heading,
    Text,
    SimpleGrid
 } from '@chakra-ui/react'

 const Options = (props) => {
    
	const [post, setPost] = React.useState({captain: '', playerList: ''});

	const initialCall = () => {
		Promise.all([
			Axios.get('player/?id=' + props.CaptainId),
			Axios.get('team/teamlist/?id=' + props.Id)
		])
		.then(([playerResponse, teamListResponse]) => {
		    setPost({ captain: playerResponse.data, playerList: teamListResponse.data});
		}, (error) => {
		})
	}

	React.useEffect(() => {
		initialCall();
    }, []);

    const numberFormat = new Intl.NumberFormat();
    const capacity = parseInt(props.StandingNo) + parseInt(props.SeatsNo)  + parseInt(props.StandingGrandNo) + parseInt(props.SeatsGrandNo) + parseInt(props.VIPNo)

    return post.playerList ? (
        <>
            <Container
                maxW='container.3xl'
                pt='75px;'
                pb='75px;'
            >
				<Box boxShadow='md' bg='white' w='100%' p={20}>
                    <Heading
                        size='xl'
                        textTransform='uppercase'
                        pt={10}
                        pb={5}
                        >
                            Team Captain
                    </Heading>
                    <Text>{ props.CaptainId ? 'Current Team Captain: ' + post.captain.firstname + ' ' + post.captain.surname : 'No Captain Selected' }</Text>
                    <TeamCaptain
                        TeamId = {props.Id}
                        CaptainId = {props.CaptainId}
                        CaptainFirstname = {post.captain.firstname}
                        CaptainSurname = {post.captain.surname}
                        PlayerList = {post.playerList}
                    />
                    <Heading
                        size='xl'
                        textTransform='uppercase'
                        pt={10}
                        pb={5}
                        >
                            Stadium
                    </Heading>
                    <Text>Stadium: { props.Stadium ?  props.Stadium : '' }</Text>
                    <Text pb={5}>Capacity: { numberFormat.format(capacity) }</Text>
                    <SimpleGrid columns={5} spacing={10}>
                        <Box>
                            <Heading
                                size='md'
                                textTransform='uppercase'
                                >
                                Standing
                            </Heading>
                            <Text>{ numberFormat.format(props.StandingNo) }</Text>
                        </Box>
                        <Box>
                            <Heading
                                size='md'
                                textTransform='uppercase'
                                >
                                Seats
                            </Heading>
                            <Text>{ numberFormat.format(props.SeatsNo) }</Text>
                        </Box>
                        <Box>
                            <Heading
                                size='md'
                                textTransform='uppercase'
                                >
                                Standing Grandstand
                            </Heading>
                            <Text>{ numberFormat.format(props.StandingGrandNo) }</Text>
                        </Box>
                        <Box>
                            <Heading
                                size='md'
                                textTransform='uppercase'
                                >
                                Seats Grandstand
                            </Heading>
                            <Text>{ numberFormat.format(props.SeatsGrandNo) }</Text>
                        </Box>
                        <Box>
                            <Heading
                                size='md'
                                textTransform='uppercase'
                                >
                                VIP
                            </Heading>
                            <Text>{ numberFormat.format(props.VIPNo) }</Text>
                        </Box>
                    </SimpleGrid>
                    <Heading
                        size='xl'
                        textTransform='uppercase'
                        pt={10}
                        pb={5}
                        >
                            Upgarde Stadium
                    </Heading>
                    <StadiumUpgrade />
                    <Heading
                        size='xl'
                        textTransform='uppercase'
                        pt={10}
                        pb={5}
                        >
                            Ground Maintenance
                    </Heading>
                    <StadiumMaintenance />
                    <Heading
                        size='xl'
                        textTransform='uppercase'
                        pt={10}
                        pb={5}
                        >
                            Training Camp
                    </Heading>
                    <TrainingCamp 
                        TeamId = {props.Id}
                    />
                </Box>
            </Container>
        </>
    ) : (
        <>
        </>
    )
 }

 export default Options