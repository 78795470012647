import React from 'react';
import Axios from 'axios';
import {Helmet} from "react-helmet";

import Hero from '../../Components/Hero';
import Content from '../../Components/Team/TeamContent';
import TeamList from '../../Components/Team/TeamList';
import ScorerList from '../../Components/Team/Scorers';
import TeamInfo from '../../Components/Team/TeamInfo';
import Contracts from '../../Components/Team/Contracts';
import Options from '../../Components/Team/Options';
import Fixture from '../../Components/Team/Fixtures';
import TeamStats from '../../Components/Team/TeamStats';

import {
	Heading,
	Container,
	Box,
	SimpleGrid,
	Card,
	Tabs,
	Tab,
	TabList,
	TabPanels,
	TabPanel
} from '@chakra-ui/react'


import { useLocation, useParams } from 'react-router-dom';

const usePathname = () => {
  const location = useLocation();
  return location.pathname;
}

const Team = (props) => {
	const { teamId } = useParams();
	const [post, setPost] = React.useState({team: null, teamList : null, league: null});

	const initialCall = () => {
		Promise.all([
			Axios.get('team/?id=' + teamId),
			Axios.get('team/teamlist/?id=' + teamId)
		])
		.then(([teamResponse, teamListResponse]) => {
		    setPost({ team: teamResponse.data, teamList: teamListResponse.data.team, league : teamResponse.data.league_id});
		}, (error) => {
		})
	}

	React.useEffect(() => {
		initialCall();

		// eslint-disable-next-line react-hooks/exhaustive-deps
  	}, []);

	const displayData = () => {
	    return post.team ? (
	      	<>
	      		<Helmet>
	            	<title>Websoccer - { post.team.name }</title>
	        	</Helmet>
	        	<Hero 
	        		FirstName={ post.team.name }
	        		PageType={usePathname}
					safeMode={props.safeMode}/>
				<Container
					maxW='container.3xl'
					pt='75px;'
					pb='75px;'
				>
				<Tabs isFitted mb="2em">
					<TabList>
						<Tab>General</Tab>
						<Tab>Squad</Tab>
						<Tab>Contracts</Tab>
						<Tab>Calendar</Tab>
						<Tab>Options</Tab>
					</TabList>
					<TabPanels>
						<TabPanel>
							<SimpleGrid columns={{sm: 2, lg: 4}} spacing={6} pt={10}>
								<Box>
									<Heading
										textTransform='uppercase'
									>
										Team History
									</Heading>
									<Card
										minH="550px"
									>
										<TeamInfo
											League={post.team.league}
											Budget={post.team.budget}
											History={post.team.history}
											Total_Won={post.team.total_won}
											Total_Draws={post.team.total_draws}
											Total_Lost={post.team.total_lost}
											Total_Goals={post.team.total_goals}
											Total_Conceded={post.team.total_conceded}
											League_Id={post.team.league_id}
											Stadium={post.team.stadium}
										/>
									</Card>
								</Box>
								<ScorerList
									Players={ post.teamList }
									safeMode={props.safeMode} />
								<Fixture
									Id={ teamId }/>
							</SimpleGrid>
						</TabPanel>
						<TabPanel>
							<TeamList
								Id={ teamId }
								National_Team={post.team.national_team}
								Players={ post.teamList }
								safeMode={props.safeMode}
							/>
						</TabPanel>
						<TabPanel>
							<Contracts
								Id={ teamId }
								Players={ post.teamList }/>
						</TabPanel>
						<TabPanel>
						</TabPanel>
						<TabPanel>
							<Options 
								Id={ teamId }
								CaptainId={post.team.captain_id}
								Stadium={post.team.stadium}
								Standing={post.team.preis_stehen}
								Seats={post.team.preis_sitz}
								SeatsGrand={post.team.preis_haupt_sitze}
								StandingGrand={post.team.preis_haupt_stehen}
								VIP={post.team.preis_vip}
								StandingNo={post.team.p_steh}
								SeatsNo={post.team.p_sitz}
								StandingGrandNo={post.team.p_haupt_steh}
								SeatsGrandNo={post.team.p_haupt_sitz}
								VIPNo={post.team.p_vip}
							/>
						</TabPanel>
					</TabPanels>
				</Tabs>
				</Container>
        			<Content 
							Id={ teamId }
							League={post.team.league}
							Budget={post.team.budget}
							History={post.team.history}
							Total_Won={post.team.total_won}
							Total_Draws={post.team.total_draws}
							Total_Lost={post.team.total_lost}
							Total_Goals={post.team.total_goals}
							Total_Conceded={post.team.total_conceded}
							League_Id={post.team.league_id}
							National_Team={post.team.national_team}
							Stadium={post.team.stadium}
							Name={post.team.name}
							CaptainId={post.team.captain_id}
							Standing={post.team.preis_stehen}
							Seats={post.team.preis_sitz}
							SeatsGrand={post.team.preis_haupt_sitze}
							StandingGrand={post.team.preis_haupt_stehen}
							VIP={post.team.preis_vip}
							StandingNo={post.team.p_steh}
							SeatsNo={post.team.p_sitz}
							StandingGrandNo={post.team.p_haupt_steh}
							SeatsGrandNo={post.team.p_haupt_sitz}
							VIPNo={post.team.p_vip}
							PitchLevel={post.team.level_pitch}
							VideoLevel={post.team.level_videowall}
							SeatsLevel={post.team.level_seatsquality}
							VIPLevel={post.team.level_vipquality}
							PitchMaintenance={post.team.maintenance_pitch}
							VideoMaintenance={post.team.maintenance_videowall}
							SeatsMaintenance={post.team.maintenance_seatsquality}
							VIPMaintenance={post.team.maintenance_vipquality}
						/>
						<TeamStats
							Players={ post.teamList }
							safeMode={props.safeMode} />
	        </>
	    ) : (
	    	<>
	      		<Helmet>
	            	<title>Websoccer - Loading...</title>
	        	</Helmet>
	        	<Hero 
	        		FirstName="Loading..."
	        		PageType={usePathname}/>
	        	<>
					<Container
						maxW='container.3xl'
						pt='75px;'
						pb='75px;'
					>
					<Box boxShadow='md' bg='white' w='100%' p={20}>
						<Heading size="2xl" textTransform="uppercase">No Team Found</Heading>
					</Box>
				</Container>
				</>
	        </>
	    );
	 }

	return(
		<>
			{displayData()}
    </>
	)
}

export default Team;