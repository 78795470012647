import React from 'react';

import HeroBackup from './HeroBackup';
import HeroSlider from './HeroSlider';
import Scorers from './Fixtures/scorers';

import {
	Box,
	Grid,
	GridItem,
	Heading,
	HStack
} from '@chakra-ui/react'

import { NumericFormat } from 'react-number-format';

const hero = (props) => {

	const background = props.HeroImage1 != null ? <HeroSlider content={props} safeMode={props.safeMode}/> : <HeroBackup safeMode={props.safeMode}/>
	

	if (props.PageType === "player"){
		return(
			<>
				<Box
					w='100%'
					minH='90vh'
					pos='relative'
				>
					
					{ background }
					<HStack
						pos='absolute'
						bottom='5vh'
						left='2vw'
						zIndex={2}
						spacing={{sm: 10, lg: 20}}
					>
						<Box>
							<Heading
								color='white'
								textTransform='uppercase'
								fontSize={{ sm: '2xl', lg: '4xl'}}
								lineHeight={1}
							>
								{props.FirstName}
							</Heading>
							<Heading
								color='teal.200'
								textTransform='uppercase'
								fontSize={{ sm: '5xl', lg: '9xl'}}
								lineHeight={1}
							>
								{props.Surname}
							</Heading>
						</Box>
						<Box
							textAlign="center"						
						>
							<Heading
								color='white'
								textTransform='uppercase'
								fontSize={{ sm: '2xl', lg: '4xl'}}
								lineHeight={1}
							>
								Games
							</Heading>
							<Heading
								color='teal.200'
								textTransform='uppercase'
								fontSize={{ sm: '5xl', lg: '9xl'}}
								lineHeight={1}
							>
								{props.TotalGames}
							</Heading>
						</Box>
						<Box
							textAlign="center"						
						>
							<Heading
								color='white'
								textTransform='uppercase'
								fontSize={{ sm: '2xl', lg: '4xl'}}
								lineHeight={1}
							>
								Goals
							</Heading>
							<Heading
								color='teal.200'
								textTransform='uppercase'
								fontSize={{ sm: '5xl', lg: '9xl'}}
								lineHeight={1}
							>
								{props.TotalGoals}
							</Heading>
						</Box>
						<Box
							textAlign="center"						
						>
							<Heading
								color='white'
								textTransform='uppercase'
								fontSize={{ sm: '2xl', lg: '4xl'}}
								lineHeight={1}
							>
								Assists
							</Heading>
							<Heading
								color='teal.200'
								textTransform='uppercase'
								fontSize={{ sm: '5xl', lg: '9xl'}}
								lineHeight={1}
							>
								{props.TotalAssists}
							</Heading>
						</Box>
						<Box
							textAlign="center"						
						>
							<Heading
								color='white'
								textTransform='uppercase'
								fontSize={{ sm: '2xl', lg: '4xl'}}
								lineHeight={1}
							>
								YC
							</Heading>
							<Heading
								color='teal.200'
								textTransform='uppercase'
								fontSize={{ sm: '5xl', lg: '9xl'}}
								lineHeight={1}
							>
								{props.TotalYC}
							</Heading>
						</Box>
						<Box
							textAlign="center"						
						>
							<Heading
								color='white'
								textTransform='uppercase'
								fontSize={{ sm: '2xl', lg: '4xl'}}
								lineHeight={1}
							>
								RC
							</Heading>
							<Heading
								color='teal.200'
								textTransform='uppercase'
								fontSize={{ sm: '5xl', lg: '9xl'}}
								lineHeight={1}
							>
								{props.TotalRC}
							</Heading>
						</Box>
					</HStack>
					
				</Box>
			</>
		)
	} else if (props.PageType === "fixture"){
		return(
			<>
				<Box
					w='100%'
					minH='90vh'
					pos='relative'
				>
					{ background }
					<Grid
						pos="absolute"
						top="50%"
						left="50%"
						transform="translate(-50%, -50%)"
						gap={5}
						templateColumns={{sm: "35% 60px 30px 60px 35%", lg: "40% 80px 30px 80px 40%"}}
						textAlign="center"
						color="white"
						zIndex={2}
						textTransform="uppercase"
						alignItems="center"
						width="100%"
					>
						<GridItem
							colSpan={5}
						>
							<Heading fontSize="xl">Attendance: <NumericFormat thousandSeparator={true} value={props.Crowd} displayType={'text'} /></Heading>
						</GridItem>
						<GridItem>
							<Heading
								fontSize={{ sm:"3xl", lg: "6xl"}}
								textAlign="right">
									{props.HomeTeam}
							</Heading>
						</GridItem>
						<GridItem>
							<Heading
								fontSize={{ sm:"3xl", lg: "6xl"}}
								bgGradient="linear(to-br, blue.900, blue.700)"
								display="flex"
								justifyContent="center"
								alignItems="center"
								borderRadius={10}
								pb={2}
								pt={2}
							>
								{props.HomeGoals}
							</Heading>
						</GridItem>
						<GridItem>
							<Heading fontSize="2xl">v</Heading>
						</GridItem>
						<GridItem>
							<Heading
								fontSize={{ sm:"3xl", lg: "6xl"}}
								bgGradient="linear(to-br, blue.900, blue.700)"
								display="flex"
								justifyContent="center"
								alignItems="center"
								borderRadius={10}
								pb={2}
								pt={2}
							>
								{props.AwayGoals}
							</Heading>
						</GridItem>
						<GridItem>
							<Heading
								fontSize={{ sm:"3xl", lg: "6xl"}}
								textAlign="left">
									{props.AwayTeam}
							</Heading>
						</GridItem>
						<Scorers
							MatchId = {props.MatchId}
							TeamList = {props.TeamList}
							HomeTeam = {props.HomeTeam}
							AwayTeam = {props.AwayTeam}
		    			/>
					</Grid>
				</Box>
			</>
		)
	} else {
		return(
			<>
				<Box
					w='100%'
					minH='90vh'
					pos='relative'
				>
					
					{ background }
					<Heading
						pos='absolute'
						bottom='5vh'
						left='2vw'
						color='white'
						zIndex={2}
						textTransform='uppercase'
						fontSize='8xl'
					>
						{props.FirstName}
					</Heading>
				</Box>
			</>
		)
	}
}

export default hero;

