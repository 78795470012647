import React from 'react';

import { Link } from 'react-router-dom';

import ContractUpdate from '../_FormFields/extendContractQuick';
import SackPlayer from '../_FormFields/sackPlayerQuick';

import { 
	Container,
	Box,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	Heading,
	Text
 } from '@chakra-ui/react'

const formatter = new Intl.NumberFormat('en-UK', {
	  style: 'currency',
	  currency: 'GBP',
	  minimumFractionDigits: 0
	});

const ContractList = (props) => {

	const displayData = () => {
		return props.Players ? (
			<>
				<TableContainer>
					<Table
						variant='striped' colorScheme='blackAlpha'
						size='md'>
					<Thead bg='#141414'>
						<Tr>
							<Th color='#ffffff'>Name</Th>
							<Th color='#ffffff'>Pos</Th>
							<Th color='#ffffff'>Skill</Th>
							<Th color='#ffffff'>Technique</Th>
							<Th color='#ffffff'>Games</Th>
							<Th color='#ffffff'>Goals</Th>
							<Th color='#ffffff'>Assists</Th>
							<Th color='#ffffff'>YC</Th>
							<Th color='#ffffff'>RC</Th>
							<Th color='#ffffff'>Contract</Th>
							<Th color='#ffffff'>Wage</Th>
							<Th color='#ffffff'>Value</Th>
							<Th color='#ffffff'>Extend Contract</Th>
							<Th color='#ffffff'>Sack Player</Th>
						</Tr>
						
					</Thead>
					<Tbody>
					{props.Players
						.sort(((player1, player2) => (player1.position < player2.position) ? -1 : 1)
							|| ((player1, player2) => (player1.mainPosition < player2.mainPosition) ? -1 : 1)
							|| ((player1, player2) => (parseInt(player1.contract) < parseInt(player2.contract)) ? -1 : 1)
							|| ((player1, player2) => (player1.surname < player2.surname) ? -1 : 1))
						.map(playerKey => {
							let PositionTranslate = playerKey.position
							let MainPositionTranslate = playerKey.mainPosition
							let transferListed = parseInt(playerKey.transfer_listed) === 1 ? <Text display='inline' color='red'>TL </Text> : ''

							if (playerKey.position === "Torwart"){
								PositionTranslate = "Goalkeeper"
							} else if (playerKey.position === "Abwehr"){
								PositionTranslate = "Defender"
							} else if (playerKey.position === "Mittelfeld"){
								PositionTranslate = "Midfielder"
							} else if (playerKey.position === "Sturm"){
								PositionTranslate = "Forward"
							}

							if (playerKey.mainPosition === "T"){
								MainPositionTranslate = "GK"
							} else if (playerKey.mainPosition === "LV"){
								MainPositionTranslate = "LD"
							} else if (playerKey.mainPosition === "IV"){
								MainPositionTranslate = "CD"
							} else if (playerKey.mainPosition === "RV"){
								MainPositionTranslate = "RD"
							} else if (playerKey.mainPosition === "LM"){
								MainPositionTranslate = "LM"
							} else if (playerKey.mainPosition === "DM"){
								MainPositionTranslate = "DM"
							} else if (playerKey.mainPosition === "ZM"){
								MainPositionTranslate = "CM"
							} else if (playerKey.mainPosition === "RM"){
								MainPositionTranslate = "RM"
							} else if (playerKey.mainPosition === "OM"){
								MainPositionTranslate = "AM"
							} else if (playerKey.mainPosition === "LS"){
								MainPositionTranslate = "LF"
							} else if (playerKey.mainPosition === "MS"){
								MainPositionTranslate = "CF"
							} else if (playerKey.mainPosition === "RS"){
								MainPositionTranslate = "RF"
							} else if (playerKey.mainPosition === ""){
								MainPositionTranslate = PositionTranslate
							}


							return(
							<Tr key={playerKey.playerId}>
								<Td>{ transferListed }<Link to={'/player/' + playerKey.playerId} >{playerKey.firstname} {playerKey.surname}</Link></Td>
								<Td>{MainPositionTranslate}</Td>
								<Td>{playerKey.skill}</Td>
								<Td>{playerKey.technique}</Td>
								<Td>{playerKey.games}</Td>
								<Td>{playerKey.goals}</Td>
								<Td>{playerKey.assists}</Td>
								<Td>{playerKey.yc}</Td>
								<Td>{playerKey.yrc + playerKey.rc}</Td>
								<Td>{playerKey.contract}</Td>
								<Td>{formatter.format(playerKey.wage)}</Td>
								<Td>{formatter.format(playerKey.value)}</Td>
								<Td>
									<ContractUpdate
										PlayerId={playerKey.playerId}
										WageOffer={playerKey.wage}
									/>
								</Td>
								<Td>
									<SackPlayer
										PlayerId={playerKey.playerId}
									/>
								</Td>
							</Tr>
						)}
					)}
					</Tbody>
					</Table>
				</TableContainer>
			</>
		) : (
			<>
				<Container
						maxW='container.3xl'
						pt='75px;'
						pb='75px;'
					>
					<Box boxShadow='md' bg='white' w='100%' p={20}>
						<Heading size="2xl" textTransform="uppercase">No Players Found</Heading>
					</Box>
				</Container>
			</>
		)
	}

	return(
		<>
			{displayData()}
		</>
	)
}

export default ContractList