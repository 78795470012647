import React, {useState} from 'react';
import Axios from 'axios';
import moment from 'moment';

import {
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Text,
	TableContainer,
  } from '@chakra-ui/react'

  const TransferInfo = (props) => {

	const [post, setPost] = useState({transferinfo : []});

	const initialCall = () => {
		Promise.all([
			Axios.get('player/transferDetails/?id=' + props.id)
		])
		.then((transferinfoResponse) => {
		    setPost({ transferinfo: transferinfoResponse[0].data.transfers });
		}, (error) => {
		})
	}

	React.useEffect(() => {
		initialCall();
  }, []);

	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'GBP',
		minimumFractionDigits: 0
	  });

	if (post.transferinfo.length > 0){

		return (
			<>
			<TableContainer>
				<Table>
					<Thead>
					<Tr>
						<Th>Date</Th>
						<Th>From</Th>
						<Th>To</Th>
						<Th>Price</Th>
					</Tr>
					</Thead>
					<Tbody>
						{post.transferinfo.sort((a, b) => {
							return moment.unix(a.date).format("DD/MM/YYYY") - 
								moment.unix(b.date).format("DD/MM/YYYY")
						}).reverse()
						.map(transferKey => {
							return(
								<Tr>
									<Td>{moment.unix(transferKey.date).format("DD/MM/YYYY")}</Td>
									{transferKey.seller ? <Td>{transferKey.seller}</Td> : <Td>Free Transfer</Td>}
									<Td>{transferKey.buyer}</Td>
									<Td>{formatter.format(transferKey.amount)}</Td>
								</Tr>
							)
						})}
					</Tbody>
				</Table>
				</TableContainer>
			</>
		)
	} else {
		<Text>
			No Transfers
		</Text>
	}

  }

  export default TransferInfo

