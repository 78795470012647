import React, {useState, useEffect} from 'react';
import Axios from 'axios';

import { 
	Card,
	CardBody,
	CardFooter,
	Grid,
	GridItem,
	Heading,
	Text,
} from '@chakra-ui/react'

const MatchReport = (props) => {
	const [post, setPost] = useState({id: props.MatchId, matchreport: []});

	useEffect(() => {
		Promise.all([
			Axios.get('fixtures/fixture/report/?id=' + post.id)
		])
		.then(([reportResponse]) => {
		    setPost({ id: props.MatchId, matchreport: reportResponse.data.match});
		}, (error) => {
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
  	}, []);

	  let report = Object.keys(post.matchreport)
	  .reverse()
	  .map((reportKey, i) => {
		  return [...Array(post.matchreport[reportKey])].map((_, i) => {
			  const names = _.playernames.split(';');
			  const reportText = () =>{
					let currentTeam
					if (_.active_team === "0"){
						currentTeam = props.AwayTeam
					} else {
						currentTeam = props.HomeTeam
					}
				  return {__html: _.message.replace('{sp1}', names[0]).replace('{sp2}', names[1]).replace('{ma1}', currentTeam)}
			  } 
			  let goalAction = false;
			  let ycAction = false;
			  let rcAction = false;
			  switch(_.message_action) {
				  case('Pass_daneben'):
					  goalAction = false
					  ycAction = false
					  rcAction = false
					  break;
				  case('Zweikampf_verloren'):
					  goalAction = false
					  ycAction = false
					  rcAction = false
					  break;
				  case('Zweikampf_gewonnen'):
					  goalAction = false
					  ycAction = false
					  rcAction = false
					  break;
				  case('Tor'):
					  goalAction = true
					  ycAction = false
					  rcAction = false
					  break;
				  case('Torschuss_daneban'):
					  goalAction = false
					  ycAction = false
					  rcAction = false
					  break;
				  case('Ecke'):
					  goalAction = false
					  ycAction = false
					  rcAction = false
					  break;
				  case('Karte_gelb'):
					  goalAction = false
					  ycAction = true
					  rcAction = false
					  break;
				  case('Karte_rot'):
					  goalAction = false
					  ycAction = false
					  rcAction = true
					  break;
				  case('Karte_gelb_rot'):
					  goalAction = false
					  ycAction = false
					  rcAction = true
					  break;
				  case('Freistoss_daneben'):
					  goalAction = false
					  ycAction = false
					  rcAction = false
					  break;
				  case('Torschuss_auf_Tor'):
					  goalAction = false
					  ycAction = false
					  rcAction = false
					  break;
				  case('Tor_mit_vorlage'):
					  goalAction = true
					  ycAction = false
					  rcAction = false
					  break;
				  case('Elfmeter_erfolg'):
					  goalAction = true
					  ycAction = false
					  rcAction = false
					  break;
				  case('Freistoss_treffer'):
					  goalAction = true
					  ycAction = false
					  rcAction = false
					  break;
				  case('Torschuss_daneben'):
					  goalAction = false
					  ycAction = false
					  rcAction = false
					  break;
				  case('Elfmeter_verschossen'):
					  goalAction = false
					  ycAction = false
					  rcAction = false
					  break;
				  default:
						goalAction = false
						ycAction = false
						rcAction = false;
			  }
			  let bgColor = 'white'
			  if (goalAction) {
				bgColor = 'blue.800'
			  } else if (ycAction) {
				bgColor = 'yellow.400'
			  } else if (rcAction) {
				bgColor = 'red.500'
			  }
			  let sideColor = 'gray.100'
			  if (goalAction) {
				sideColor = 'teal.200'
			  } else if (ycAction) {
				sideColor = 'yellow.300'
			  } else if (rcAction) {
				sideColor = 'red.700'
			  }
			  return (
				<>
				<Card
					direction={{ base: 'column', sm: 'row' }}
					overflow='hidden'
					variant='outline'
					mb={5}
					>
					<Grid 
						templateColumns="100px 1fr"
						width="100%"
					>
						<GridItem
							bg={sideColor}
							height="100%"
							textAlign="center"
						>
							<Heading pt={7}> {_.minute}'</Heading>
						</GridItem>
						<GridItem
							bg={bgColor}
							color={goalAction ? 'white' : "black"}
						>
							<CardBody>
								<Heading fontSize="xl">{props.HomeTeam} {_.goals} {props.AwayTeam}</Heading>
							</CardBody>
							<CardFooter>
								<Text dangerouslySetInnerHTML={reportText()}></Text>
							</CardFooter>
						</GridItem>
					</Grid>
					</Card>
				</>
			  )
		  })
	  });

	return(
		<>
			{report}
		</>
	)
}

export default MatchReport;