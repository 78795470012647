import axios from 'axios';

export const transferOffer = (res, req) =>{
	const options = {
	  withCredentials: false,
	};
	axios.post('https://ws.blue-phoenix.co.uk/webservices/api/player/transferList/create.php', {
	    player_id: res.id,
	    team_id: 1,
	    offer_team_id: res.teamId,
	    offer: res.transferValue,
	    wage: res.wageValue,
	    contract: res.contractValue,
	    bonus: res.bonusValue
	  }, options)
	  .then(response => {
	    console.log(response);
	  })
	  .catch(error => {
	    console.log(error, res.id);
	  });
}

export const bidAccept = (res, req) =>{
	const options = {
		withCredentials: false,
	};
	axios.post('https://ws.blue-phoenix.co.uk/webservices/api/player/transferOffer/update.php', {
		player_id: parseInt(res.player_id),
		completed: 1,
	}, options)
	.then(response => {
		return axios.post('https://ws.blue-phoenix.co.uk/webservices/api/player/transferOffer/updateAccepted.php', {
			id: parseInt(res.id),
			accepted: 1,
		}, options)
	})
	.catch(error => {
	})
	.then(response => {
		return axios.post('https://ws.blue-phoenix.co.uk/webservices/api/player/transferOffer/movePlayer.php', {
			id: parseInt(res.player_id),
			transfermarkt: 0,
			verein_id: parseInt(res.offer_team_id),
			seller_id: parseInt(res.original_team_id) ? parseInt(res.original_team_id) : 1,
			marktwert: parseInt(res.offer),
			vertrag_gehalt: parseInt(res.wage),
			vertrag_spiele: parseInt(res.contract),
			vertrag_torpraemie: parseInt(res.bonus)
		}, options)
	}).catch((error) => {
	})
	.then(response => {
		return axios.post('https://ws.blue-phoenix.co.uk/webservices/api/player/transferOffer/history.php', {
			player_id: parseInt(res.player_id),
			seller_id:  parseInt(res.original_team_id),
			buyer_id: parseInt(res.offer_team_id),
			datum: Math.floor(Date.now()) / 1000,
			transfer_amount: parseInt(res.offer)
		}, options)
	})
	.catch((error) => {
	})
	.then(response => {
	})
	.catch((error) => {
	})
}

export const sackPlayer = (res, req) =>{
	const options = {
	  withCredentials: false,
	};
	axios.post('https://ws.blue-phoenix.co.uk/webservices/api/player/sack/update.php', {
	    id: res.playerId,
	  }, options)
	  .then(response => {
	    console.log(response);
	  })
	  .catch(error => {
	  });
}

export const contractPlayer = (res, req) =>{
	const options = {
	  withCredentials: false,
	};
	axios.post('https://ws.blue-phoenix.co.uk/webservices/api/player/contract/update.php', {
	    id: res.playerId,
	    vertrag_spiele: 100,
	    vertrag_gehalt: res.wageOffer
	  }, options)
	  .then(response => {
	  })
	  .catch(error => {
	  });
}

export const transferUpdate = (res, req) =>{
	const options = {
	  withCredentials: false,
	};
	console.log(res, req);
	axios.post('https://ws.blue-phoenix.co.uk/webservices/api/player/transferList/update.php', {
	    id: parseInt(res.playerId),
	    transfermarkt: res.transferStatus,
	    transfer_start: 1,
	    transfer_ende: 1,
	    transfer_mindestgebot: parseInt(res.transferValue),
	  }, options)
	  .then(response => {
	  })
	  .catch(error => {
	  });
}

export const captainUpdate = (res, req) =>{
	const options = {
	  withCredentials: false,
	};
	axios.post('https://ws.blue-phoenix.co.uk/webservices/api/team/captain/update.php', {
	    id: res.id,
	    captain_id: res.captainId,
	  }, options)
	  .then(response => {
	  })
	  .catch(error => {
	  });
}

export const trainingCampUpdate = (res, req) =>{
	const options = {
	  withCredentials: false,
	};
	console.log(res, req);
	axios.post('https://ws.blue-phoenix.co.uk/webservices/api/team/trainingcamp/create.php', {
	    verein_id: parseInt(res.id),
	    lager_id: 12,
	    datum_start: Math.floor(Date.now()/1000),
	    datum_ende: Math.floor((Date.now())/1000 + 604800)
	  }, options)
	  .then(response => {
	    console.log(response);
	  })
	  .catch(error => {
		console.log(error);
	  });
}

export const NationalRemove = (res, req) =>{
	const options = {
	  withCredentials: false,
	};
	axios.post('https://ws.blue-phoenix.co.uk/webservices/api/national/delete.php', {
	    player_id: parseInt(res.playerId),
	  }, options)
	  .then(response => {
	  })
	  .catch(error => {
	  });
}

export const NationalAdd = (res, req) =>{
	const options = {
	  withCredentials: false,
	};
	axios.post('https://ws.blue-phoenix.co.uk/webservices/api/national/create.php', {
		player_id: parseInt(res.playerId),
	    team_id: parseInt(res.teamId)
	  }, options)
	  .then(response => {
	  })
	  .catch(error => {
	  });
}