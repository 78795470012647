import React, {useState} from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';

import {
	GridItem,
	Card,
	CardBody,
	AspectRatio, 
    Heading,
    Image,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
} from '@chakra-ui/react'

const Yellow = (props) => {

	const [data, setData] = useState({ stats:[], title: 'Most Yellow Cards' });

	React.useEffect(() => {
			Promise.all([
				Axios.get('player/allStats/yellow.php')
			])
			.then(([statsResponse]) => {
				setData({ stats : statsResponse.data, title: 'Most Yellow Cards'});
			}, (error) => {
			})
	}, []);



    let playerImage = <Image src="https://images2.imgbox.com/32/ff/MwCs8AHw_o.jpg" alt="" />

    if (props.safeMode == 1 ) {
        playerImage = <Image src="https://images2.imgbox.com/32/ff/MwCs8AHw_o.jpg" alt="" />
    } else {
        if (data.stats != null){
            data.stats.slice(4, 5).map(statsKey => {
                if (statsKey.hero){
                    return(
                        playerImage = <Image src={statsKey.hero} alt={statsKey.last_name} />
                    )
                }
            })

            data.stats.slice(3, 4).map(statsKey => {
                if (statsKey.hero){
                    return(
                        playerImage = <Image src={statsKey.hero} alt={statsKey.last_name} />
                    )
                }
            })

            data.stats.slice(2, 3).map(statsKey => {
                if (statsKey.hero){
                    return(
                        playerImage = <Image src={statsKey.hero} alt={statsKey.last_name} />
                    )
                }
            })

            data.stats.slice(1, 2).map(statsKey => {
                if (statsKey.hero){
                    return(
                        playerImage = <Image src={statsKey.hero} alt={statsKey.last_name} />
                    )
                }
            })

            data.stats.slice(0, 1).map(statsKey => {
                if (statsKey.hero){
                    return(
                        playerImage = <Image src={statsKey.hero} alt={statsKey.last_name} />
                    )
                }
            })
        } else {
            playerImage = <Image src="https://images2.imgbox.com/32/ff/MwCs8AHw_o.jpg" alt="" />
        }
    }

		
    return data.stats ? (
        <>
            <GridItem>
                <Card>
                    <AspectRatio ratio={4 / 3}>
                        {playerImage}
                    </AspectRatio>
                    <CardBody>
                        <Heading
                            textTransform='uppercase'
                        >
                            {data.title}
                        </Heading>
                        <TableContainer>
                            <Table variant='striped' colorScheme='teal'>
                                <Thead>
                                    <Tr>
                                        <Th>Name</Th>
                                        <Th isNumeric>Matches</Th>
                                        <Th isNumeric>Yellow Cards</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                { data.stats.map(statsKey => {
                                    return(
                                    <Tr key={statsKey.id}>
                                        <Td _hover={{color: "teal.500"}}><Link to={'/player/' + statsKey.player_id}>{statsKey.first_name} {statsKey.last_name}</Link></Td>
                                        <Td isNumeric>{statsKey.games}</Td>
                                        <Td textAlign="right">{statsKey.yc}</Td>
                                    </Tr>
                                )})}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </CardBody>
                </Card>
            </GridItem>
        </>
    ) : (
        <GridItem>
        </GridItem>
    )
}

export default Yellow;