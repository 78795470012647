import React from 'react';
import {Helmet} from "react-helmet";
import Hero from '../Components/Hero';
import { useLocation } from 'react-router-dom';

import { Grid, 
	Container,
} from '@chakra-ui/react'

import Minutes from '../Components/Stats/Minutes';
import Matches from '../Components/Stats/Matches';
import Goals from '../Components/Stats/Goals';
import Assists from '../Components/Stats/Assists';
import Yellow from '../Components/Stats/Yellow';
import Red from '../Components/Stats/Red';
import Rating from '../Components/Stats/Rating';
import Age from '../Components/Stats/Age';


const Home = (props) => {
	const usePathname = () => {
		const location = useLocation();
		return location.pathname;
	}

	return(
		<>
			<Helmet>
				<title>Websoccer</title>
			</Helmet>
			<Hero 
				PageType={usePathname}
				safeMode={props.safeMode}	
			/>
			<Container
					maxW='container.3xl'
					pt='75px;'
					pb='75px;'
				>
					<Grid
						templateColumns={{ base : 'repeat(1, 1fr)', md : 'repeat(2, 1fr)', xl: 'repeat(3, 1fr)'}}
						gap={6}
					>
						<Goals
							safeMode={props.safeMode}
						/>
						
						<Assists
							safeMode={props.safeMode}
						/>
						<Matches
							safeMode={props.safeMode}
						/>
						<Yellow
							safeMode={props.safeMode}
						/>
						<Red
							safeMode={props.safeMode}
						/>
						<Rating
							safeMode={props.safeMode}
						/>
						<Minutes
							safeMode={props.safeMode}
						/>
						<Age
							safeMode={props.safeMode}
						/>
					</Grid>
				</Container>
		</>
	)
}

export default Home