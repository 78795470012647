import React from "react";
import { Formik, Form } from 'formik';

import { sackPlayer } from "../update";

import { 
    FormControl,
    FormLabel,
    Button,
    Heading
 } from '@chakra-ui/react'

const sackedPlayer = (props) => {

    return(
        <>
            <Formik
            initialValues={{ playerId: props.PlayerId }}
            onSubmit={async (values, actions) => {
                setTimeout(() => {
                    sackPlayer(values)
                }, 500)
              }}
            >
                <Form>
                    <FormControl>
                        <FormLabel>
                            <Heading fontSize="xl" textTransform='uppercase' pt={5}>
                                Sack Player
                            </Heading>
                        </FormLabel>
                        <Button
                            colorScheme='red'
                            isLoading={props.isSubmitting}
                            type='submit'
                            mt={5}
                        >
                            Sack Player
                        </Button>
                    </FormControl>
                </Form>
            </Formik>
        </>
    )
}

export default sackedPlayer