import React, { Component }from 'react';
import Axios from 'axios';

import {
	Heading,
	Container,
	Grid,
	GridItem,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	Divider,
	Text,
	CircularProgress,
	CircularProgressLabel,
	Box,
	Flex
} from '@chakra-ui/react'

class PlayerStats extends Component {
	state = {
		stats:[]
	}
	componentWillMount(){
		Promise.all([
			Axios.get('player/playerStats/?id=' + this.props.Id)
		])
			.then(([statsResponse]) => {
				this.setState({stats: statsResponse.data.match});
			});
	}
	render(){

		const totalPasses = this.state.stats.reduce((a, currValue) => parseFloat(a) + parseFloat(currValue.passes_successful), 0);
		const missedPasses = this.state.stats.reduce((a, currValue) => parseFloat(a) + parseFloat(currValue.passes_failed), 0);
		const passPercent = (totalPasses / (totalPasses + missedPasses)) * 100

		const totalTackles = this.state.stats.reduce((a, currValue) => parseFloat(a) + parseFloat(currValue.won_tackles), 0);
		const missedTackles = this.state.stats.reduce((a, currValue) => parseFloat(a) + parseFloat(currValue.lost_tackles), 0);
		const tacklePercent = (totalTackles / (totalTackles + missedTackles)) * 100

		const totalShots = this.state.stats.reduce((a, currValue) => parseFloat(a) + parseFloat(currValue.shots), 0);
		const goals = this.props.TotalGoals;
		const shotPercent = (goals / totalShots) * 100
		
		return(
			<>
				<Container
				maxW='container.3xl'
				pt='75px;'
				pb='75px;'>
					<Grid templateColumns={{ sm:'repeat(2, 1fr)', lg: 'repeat(4, 1fr)'}} gap={6}>
						<GridItem colSpan={{ sm:2, lg: 4}} pb={10}>
							<Heading
								textTransform="uppercase"
							>
								Player Stats
							</Heading>
						</GridItem>
						<GridItem>
							<Card>
								<CardHeader>
									<Heading
										fontSize="xl"
										textTransform="uppercase"
									>
										Defensive Stats
									</Heading>
								</CardHeader>
								<Divider/>
								<CardBody>
									<Grid gridTemplateColumns="repeat(2, 1fr)">
										<GridItem textAlign="center" borderRight="1px solid gray">
											<Heading color="teal.200">
												{totalTackles}
											</Heading>
											<Text>
												Total Tackles
											</Text>
										</GridItem>
										<GridItem textAlign="center">
											<Heading color="teal.200">
												{missedTackles}
											</Heading>
											<Text>
												Missed Tackles
											</Text>
										</GridItem>
									</Grid>
								</CardBody>
								<Divider/>
								<CardFooter display="flex">
									<Flex justifyContent="center" flexWrap="wrap" width="100%">
										<Box>
										<CircularProgress value={tacklePercent.toFixed(1)} size='120px' color='blue.800'>
											<CircularProgressLabel fontSize="md">{tacklePercent.toFixed(1)}%</CircularProgressLabel>
										</CircularProgress>
										</Box>
										<Heading textAlign="center" textTransform="uppercase" fontSize="xl" width="100%">
											Tackle Completion Rate
										</Heading>
									</Flex>
									
								</CardFooter>
							</Card>
						</GridItem>
						<GridItem>
							<Card>
								<CardHeader>
									<Heading
										fontSize="xl"
										textTransform="uppercase"
									>
										Passing Stats
									</Heading>
								</CardHeader>
								<Divider/>
								<CardBody>
									<Grid gridTemplateColumns="repeat(2, 1fr)">
										<GridItem textAlign="center" borderRight="1px solid gray">
											<Heading color="teal.200">
												{totalPasses}
											</Heading>
											<Text>
												Total Passes
											</Text>
										</GridItem>
										<GridItem textAlign="center">
											<Heading color="teal.200">
												{missedPasses}
											</Heading>
											<Text>
												Missed Passes
											</Text>
										</GridItem>
									</Grid>
								</CardBody>
								<Divider/>
								<CardFooter display="flex">
									<Flex justifyContent="center" flexWrap="wrap" width="100%">
										<Box>
										<CircularProgress value={passPercent.toFixed(1)} size='120px' color='blue.800'>
											<CircularProgressLabel fontSize="md">{passPercent.toFixed(1)}%</CircularProgressLabel>
										</CircularProgress>
										</Box>
										<Heading textAlign="center" textTransform="uppercase" fontSize="xl" width="100%">
											Passing Completion Rate
										</Heading>
									</Flex>
									
								</CardFooter>
							</Card>
						</GridItem>
						<GridItem>
							<Card>
								<CardHeader>
									<Heading
										fontSize="xl"
										textTransform="uppercase"
									>
										Attacking Stats
									</Heading>
								</CardHeader>
								<Divider/>
								<CardBody>
									<Grid gridTemplateColumns="repeat(2, 1fr)">
										<GridItem textAlign="center" borderRight="1px solid gray">
											<Heading color="teal.200">
												{totalShots}
											</Heading>
											<Text>
												Total Shots
											</Text>
										</GridItem>
										<GridItem textAlign="center">
											<Heading color="teal.200">
												{goals}
											</Heading>
											<Text>
												Goals
											</Text>
										</GridItem>
									</Grid>
								</CardBody>
								<Divider/>
								<CardFooter display="flex">
									<Flex justifyContent="center" flexWrap="wrap" width="100%">
										<Box>
										<CircularProgress value={shotPercent.toFixed(1)} size='120px' color='blue.800'>
											<CircularProgressLabel fontSize="md">{shotPercent.toFixed(1)}%</CircularProgressLabel>
										</CircularProgress>
										</Box>
										<Heading textAlign="center" textTransform="uppercase" fontSize="xl" width="100%">
											Scoring Rate
										</Heading>
									</Flex>
									
								</CardFooter>
							</Card>
						</GridItem>
						<GridItem>
							<Card>
								<CardHeader>
									<Heading
										fontSize="xl"
										textTransform="uppercase"
									>
										Discipline
									</Heading>
								</CardHeader>
								<Divider/>
								<CardBody>
									<Grid gridTemplateColumns="repeat(2, 1fr)">
										<GridItem textAlign="center" borderRight="1px solid gray">
											<Box
												bg="yellow.200"
												border="5px solid"
												borderColor="yellow.400"
												borderRadius="10px"
												width="70%"
												margin="auto"
												pt={20}
												pb={20}
												mb={4}
											>
												<Heading color="yellow.400">
													{this.props.Yellow}
												</Heading>
											</Box>
											<Text>
												Yellow Cards
											</Text>
										</GridItem>
										<GridItem textAlign="center">
											<Box
												bg="red.600"
												border="5px solid"
												borderColor="red.800"
												borderRadius="10px"
												width="70%"
												margin="auto"
												pt={20}
												pb={20}
												mb={4}
											>
												<Heading color="red.800">
													{this.props.Red}
												</Heading>
											</Box>
											<Text>
												Red Cards
											</Text>
										</GridItem>
									</Grid>
								</CardBody>
							</Card>
						</GridItem>
					</Grid>
				</Container>
			</>
		)
	}
}

export default PlayerStats;