import React from "react";
import { Formik, Field, Form } from 'formik';

//import { captainUpdate } from "../update";

import { 
    FormControl,
    Input,
    Button,
    Box,
    Heading,
    SimpleGrid,
    Flex,
    Text,
 } from '@chakra-ui/react'

const StadiumMaintenance = (props) => {

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 0
    });

    let extensionPrice = 0

    return(
        <>
            <Formik
            initialValues={{ 
                stadiumId: '',
                teamId: props.TeamId,
                standing:'',
                seats:'',
                standingGrand:'',
                seatsGrand:'',
                vip:'',
                price: extensionPrice
            }}
            onChange={() => {
                console.log('changing');
            }}
            onSubmit={async (values, actions) => {
                setTimeout(() => {
                    alert(JSON.stringify(values, null, 2));
                    //captainUpdate(values)
                }, 500)
              }}
            >
                {(props) => (
                    <Form>
                        <FormControl pt={7}>
                            <SimpleGrid columns={4} spacing={10}>
                                <Box>
                                    <Heading
                                        size='md'
                                        textTransform='uppercase'
                                        >
                                        Grass Quality
                                    </Heading>
                                    <Field
                                        name="standing"
                                    >
                                        {({field}) => (
                                            <Input {...field} type='number' onKeyUp={props.onChange}/>
                                        )}
                                    </Field>
                                </Box>
                                <Box>
                                    <Heading
                                        size='md'
                                        textTransform='uppercase'
                                        >
                                        Video Wall
                                    </Heading>
                                    <Field
                                        name="seats"
                                    >
                                        {({field}) => (
                                            <Input {...field} type='number' />
                                        )}
                                    </Field>
                                </Box>
                                <Box>
                                    <Heading
                                        size='md'
                                        textTransform='uppercase'
                                        >
                                        Seats Quality
                                    </Heading>
                                    <Field
                                        name="standingGrand"
                                    >
                                        {({field}) => (
                                            <Input {...field} type='number' />
                                        )}
                                    </Field>
                                </Box>
                                <Box>
                                    <Heading
                                        size='md'
                                        textTransform='uppercase'
                                        >
                                        VIP Lounge Quality
                                    </Heading>
                                    <Field
                                        name="seatsGrand"
                                    >
                                        {({field}) => (
                                            <Input {...field} type='number' />
                                        )}
                                    </Field>
                                </Box>
                            </SimpleGrid>
                                <Flex alignItems="center" justifyContent="space-between">
                                    <Button
                                        colorScheme='teal'
                                        isLoading={props.isSubmitting}
                                        type='submit'
                                        mt={5}
                                    >
                                        Maintain Stadium
                                    </Button>
                                    <Text>Upgrade Cost: {formatter.format(extensionPrice)}</Text>
                                </Flex>
                        </FormControl>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default StadiumMaintenance