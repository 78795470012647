import React, { useState, useEffect} from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

import Hero from '../Components/Hero';

import { Grid, 
	GridItem, 
	Container,
	Card,
	CardBody,
	Heading,
	Avatar,
} from '@chakra-ui/react'

const NationalTeams = (props) => {
	const [data, setData] = useState({ teams: [], title: 'National Teams' });

	useEffect(() => {
			Promise.all([
				Axios.get('teams/')
			])
			.then(([teamsResponse]) => {
				setData({ teams : teamsResponse.data, title: 'National Teams'});
			}, (error) => {
			})
	}, []);

	return(
		<>
			<Helmet>
				<title>Websoccer - National Teams</title>
			</Helmet>
			<Hero 
				FirstName={data.title}
				PageType="teams"
				safeMode={props.safeMode} />
			<Container
					maxW='container.3xl'
					pt='75px;'
					pb='75px;'
				>
				<Grid
					templateColumns={{ sm:'repeat(2, 1fr)', lg: 'repeat(5, 1fr)' }}
					gap={6}
				>
					{data.teams
							.filter(teamKey => teamKey.nationalteam === "1")
							.sort(({name: previousName}, {name: currentName}) => previousName > currentName ? 1 : -1)
							.map(teamKey => {
							return(
								<GridItem>
									<Link key={teamKey.id} to={'/national-teams/' + teamKey.id}>
										<Card
											minH='100px'>
											<CardBody
												display='flex'
												alignItems='center'
												justifyContent='center'
												textAlign="center"
												flexWrap="wrap"
											>
												<Heading
													textTransform='uppercase'
													fontSize='2xl'
												>
													{teamKey.name}
													<Avatar 
														size='2xs'
														src={'https://ws.blue-phoenix.co.uk/img/flags/players/' + teamKey.name + '.png'}
													 	name={teamKey.nation}
														ml={2} />
												</Heading>
											</CardBody>
										</Card>
									</Link>
								</GridItem>
							)
						})}
				</Grid>
			</Container>
		</>
	)
}

export default NationalTeams;