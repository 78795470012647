import React from 'react';
import { Link } from 'react-router-dom';

import { 
	Container,
	Box,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	Heading
 } from '@chakra-ui/react'

const LeagueTable = (props) => {
	const displayData = () => {
		return props.Teams ? (
			<>
				<Container
					maxW='container.3xl'
					pt='75px;'
					pb='75px;'
				>
					<Box boxShadow='md' bg='white' w='100%' p={20}>
						<TableContainer>
							<Table
								variant='striped' colorScheme='blackAlpha'
								size='lg'>
							<Thead bg='#141414'>
								<Tr>
									<Th color='#ffffff'>Pos</Th>
									<Th color='#ffffff'>Team</Th>
									<Th color='#ffffff'>Pld</Th>
									<Th color='#ffffff'>W</Th>
									<Th color='#ffffff'>D</Th>
									<Th color='#ffffff'>L</Th>
									<Th color='#ffffff'>GF</Th>
									<Th color='#ffffff'>GA</Th>
									<Th color='#ffffff'>GD</Th>
									<Th color='#ffffff'>Pts</Th>
								</Tr>
								
							</Thead>
							<Tbody>
							{props.Teams
								.sort(function (team1, team2) {
									if (parseInt(team1.points) > parseInt(team2.points)) return -1;
									if (parseInt(team1.points) < parseInt(team2.points)) return 1;
									if (parseInt((team1.goals - team1.conceded)) > parseInt((team2.goals - team2.conceded))) return -1;
									if (parseInt((team1.goals - team1.conceded)) < parseInt((team2.goals - team2.conceded))) return 1;
									if (parseInt(team1.goals) > parseInt(team2.goals)) return -1;
									if (parseInt(team1.goals) < parseInt(team2.goals)) return 1;
									if (team1.team > team2.team) return 1;
									if (team1.team < team2.team) return -1;
								})
								.map((teamKey, i) =>{
									if (props.PromotionDetails){
										let relegationClass = ''
										{props.PromotionDetails.map((promotionKey, p) =>{
											if(promotionKey.details.includes('Relegation') && ((i + 1) >= promotionKey.top_team)){
												relegationClass = 'table__rel'
														
											} else if (promotionKey.details.includes('Promotion') && ((i + 1) <= promotionKey.bottom_team)){
												relegationClass = 'table__prom'
											}
										})}
										return (
											<Tr key={teamKey.id} className={relegationClass}>
												<Td>{i + 1}</Td>
												<Td>{teamKey.national === "1" ? <Link to={'/national-teams/' + teamKey.id}>{teamKey.team}</Link> : <Link to={'/teams/' + teamKey.id}>{teamKey.team}</Link>}</Td>
												<Td>{teamKey.played}</Td>
												<Td>{teamKey.won}</Td>
												<Td>{teamKey.draws}</Td>
												<Td>{teamKey.lost}</Td>
												<Td>{teamKey.goals}</Td>
												<Td>{teamKey.conceded}</Td>
												<Td>{teamKey.goals - teamKey.conceded}</Td>
												<Td>{teamKey.points}</Td>
											</Tr>
										)
										} else {
											return (
												<Tr key={teamKey.id}>
													<Td>{i + 1}</Td>
													<Td>{teamKey.national === "1" ? <Link to={'/national-teams/' + teamKey.id}>{teamKey.team}</Link> : <Link to={'/teams/' + teamKey.id}>{teamKey.team}</Link>}</Td>
													<Td>{teamKey.played}</Td>
													<Td>{teamKey.won}</Td>
													<Td>{teamKey.draws}</Td>
													<Td>{teamKey.lost}</Td>
													<Td>{teamKey.goals}</Td>
													<Td>{teamKey.conceded}</Td>
													<Td>{teamKey.goals - teamKey.conceded}</Td>
													<Td>{teamKey.points}</Td>
												</Tr>
												)
											}
										})}
							
							</Tbody>
							</Table>
						</TableContainer>
					</Box>
				</Container>
			</>
		) : (
			<Container
					maxW='container.3xl'
					pt='75px;'
					pb='75px;'
				>
				<Box boxShadow='md' bg='white' w='100%' p={20}>
					<Heading size="2xl" textTransform="uppercase">No League Table Found</Heading>
				</Box>
			</Container>
		)
	}

	return(
		<>
			{displayData()}
		</>
	)
}

export default LeagueTable;